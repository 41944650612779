import React, { useEffect, useState } from 'react';
import { ITUserData } from '../types/user.type';
import {
	axiosInstance,
	getRequestWithToken,
	patchRequestJSONWithToken,
	postRequestJSON
} from '../services/api.service';
import { useNavigate } from 'react-router-dom';
import { removeItem } from '../lib/docCookies';
import { useDispatch } from 'react-redux';
import { updateEvents, updatePlayer, updatePlayerEvents } from './dataSlice';

export interface UserContextProps {
	user?: ITUserData;
	isAdmin: boolean;
	myEvents: any;
	myTeams: any;
	getMyInfo: () => Promise<ITUserData>;
	login: (credentials: { email: string; password: string }) => Promise<void>;
	logout: () => void;
	updateUser: (updates: Partial<ITUserData>) => Promise<void>;
	asyncGetUserInfo: () => Promise<void>;
}

export const UserContext = React.createContext<UserContextProps | undefined>(
	undefined
);

interface UserContextProviderProps {}

export const UserContextProvider = ({
	children
}: React.PropsWithChildren<UserContextProviderProps>) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [user, setUser] = useState<ITUserData | undefined>(undefined);
	const [myTeams, setMyTeams] = useState<ITUserData | undefined>(undefined);
	const [myEvents, setMyEvents] = useState<ITUserData | undefined>(undefined);
	const [isAdmin, setAdmin] = useState<boolean>(false);
	useEffect(() => {
		setAdmin(user !== null && user?.role === 'admin');
		axiosInstance.interceptors.response.use(
			response => {
				return response;
			},
			error => {
				if (error.response.status === 401) {
					logout();
				}
				return error;
			}
		);
	}, [user]);

	useEffect(() => {
		asyncGetUserInfo();
	}, []);

	const asyncGetUserInfo = async () => {
		// TODO: cleanup
		const currentUser = await getMyInfo();
		setUser(currentUser);
		await dispatch(
			//We use currently this player
			updatePlayer(user)
		);
		const results = await getRequestWithToken('/me/teams');
		const teams = results.data.data.teams;
		const events = results.data.data.events;
		const myTeamsTemp: any = {};
		teams?.forEach(el => {
			myTeamsTemp[el.eventId || el.id] = el;
		});
		setMyTeams(myTeamsTemp);
		const myEventsTemp: any = {};
		events?.forEach(el => {
			myEventsTemp[el.eventId || el.id] = el;
		});
		setMyEvents(myEventsTemp);

		await dispatch(updatePlayerEvents(teams));
		await dispatch(updateEvents(events));
		// await dispatch(updateMyEvents(myEvents));
		// await dispatch(updateMyTeams(myTeams));
	};

	const login = async (credentials: { email: string; password: string }) => {
		try {
			const response = await postRequestJSON('/api/login', credentials);
			setUser(response.data);
		} catch (error) {
			console.error(error);
		}
	};

	const updateUser = async (updates: Partial<ITUserData>) => {
		if (!user) throw new Error("Can't update user data without a logged-in user");

		try {
			const response = await patchRequestJSONWithToken('/me', {
				user: updates
			});
			setUser(response.data.data.user);
		} catch (error) {
			console.error(error);
		}
	};

	const logout = () => {
		setUser(undefined);
		removeItem('userToken');
		localStorage.clear();
		navigate('/login');
	};

	const getMyInfo = async (): Promise<ITUserData> => {
		try {
			const results = await getRequestWithToken('/me');
			return results.data.data.user;
		} catch (error) {
			logout();
			throw error;
		}
	};

	return (
		<UserContext.Provider
			value={{
				user,
				isAdmin,
				myEvents,
				myTeams,
				login,
				logout,
				getMyInfo,
				updateUser,
				asyncGetUserInfo
			}}
		>
			{children}
		</UserContext.Provider>
	);
};
UserContextProvider.displayName = 'UserContextProvider';
