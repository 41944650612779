import {
	Alert,
	Box,
	TextField,
	Typography,
	FormGroup,
	IconButton,
	InputAdornment,
	Link
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import logo from '../../Assets/logo.png';
import { ResetPassword } from '../../services/auth.service';
import { MyButton } from '../../Components/Atoms/MyButton/MyButton';
import { PageCentered } from '../Page/PageCentered';
import background_login from '../../Assets/background.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface ResetPasswordPageProps {
	prop1?: string;
}

const defautlError = 'Mots de passe non identiques';
const successMessage = 'Votre mot de passe a bien été mise à jour !';

export const ResetPasswordPage = ({
	prop1,
	...props
}: ResetPasswordPageProps) => {
	const [authing, setAuthing] = useState<boolean | undefined>(undefined);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [errorMsg, setErrorMsg] = useState<string>(defautlError);
	const [oobCode, setOobCode] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const oobCodeParam = searchParams.get('oobCode');
		if (oobCodeParam) {
			setOobCode(oobCodeParam);
		}
	}, [location.search]);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (password !== confirmPassword) {
			// Handle password mismatch error
			setError(true);
			setErrorMsg(defautlError);
			return;
		}

		try {
			ResetPassword(oobCode, password)
				.then(result => {
					setSuccess(true);
					setTimeout(() => {
						setSuccess(false);
					}, 10000); // remove error message after 10 seconds
				})
				.catch(error => {
					console.error(`Error: ${error}`);
					DisplayTemporaryErrorMessage(error);
				});
			// Password reset successful, redirect to login page or show success message
		} catch (error) {
			// Handle password reset error
		}
		// Handle form submission here
	};

	function displayError(errorMessage) {
		setError(true);
		setErrorMsg(errorMessage);
	}

	function DisplayTemporaryErrorMessage(errorMessage, timing = 10000) {
		displayError(errorMessage);
		setTimeout(() => {
			setError(false);
		}, timing); // remove error message after timing milliseconds
	}

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	const handleConfirmPasswordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setConfirmPassword(event.target.value);
	};

	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleToggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	return (
		<PageCentered
			bgColor='primary.main'
			bgImg={background_login}
			centeredContent={true}
		>
			<Box
				sx={{
					margin: 'auto',
					boxSizing: 'border-box',
					display: 'flex',
					alignItems: 'center',
					verticalAlign: 'middle',
					height: '100%',
					p: 2,
					pt: 1,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					overflow: 'hidden'
				}}
			>
				<Box
					sx={{
						width: '100%',
						flexDirection: 'column',
						display: 'flex',
						alignItems: 'center',
						overflow: 'hidden'
					}}
				>
					{authing === false && (
						<Alert severity='error'>
							Cet email n'existe pas. Veuillez contacter l'administrateur de
							l'événement.
						</Alert>
					)}
					<Box
						sx={{
							typography: 'h1',
							marginBottom: '2rem',
							marginTop: '1rem',
							textAlign: 'center'
						}}
					>
						Réinitialiser son mot de passe
					</Box>
					<Box sx={{ width: '80%', maxWidth: '80%' }}>
						<TextField
							autoFocus
							fullWidth
							margin='dense'
							id='password'
							label='password'
							type={showPassword ? 'text' : 'password'}
							value={password}
							onChange={handlePasswordChange}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton onClick={handleTogglePasswordVisibility}>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</Box>
					<Box sx={{ width: '80%', maxWidth: '80%' }}>
						<TextField
							autoFocus
							fullWidth
							margin='dense'
							id='password'
							label='confirmation'
							type={showConfirmPassword ? 'text' : 'password'}
							value={confirmPassword}
							onChange={handleConfirmPasswordChange}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton onClick={handleToggleConfirmPasswordVisibility}>
											{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</Box>
					<Box>
						<Typography>
							<Link href='/login'>Se connecter </Link>
						</Typography>
					</Box>
					<Box sx={{ width: '80%', maxWidth: '90%' }}>
						<form onSubmit={handleSubmit}>
							<FormGroup
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-start'
								}}
							>
								{error && <Typography color='error'>{errorMsg}</Typography>}
								{success && (
									<Typography fontSize='0.7rem' color='green'>
										{successMessage}
									</Typography>
								)}
							</FormGroup>
							<Box sx={{ textAlign: 'center' }}>
								<MyButton
									variant='contained'
									type='submit'
									sx={{
										width: '80%',
										borderRadius: 10,
										height: '3rem',
										marginTop: {
											xs: '1rem',
											sm: '1.3rem',
											md: '1.7rem'
										},
										marginBottom: '0.5rem'
									}}
								>
									Réinitialiser
								</MyButton>
							</Box>
						</form>
					</Box>
				</Box>
			</Box>
		</PageCentered>
	);
};
