import { collection, doc, query, where, documentId } from 'firebase/firestore';
import { firestore } from '../firebase';

const db = collection(firestore, 'events');

export const eventGetAll = () => {
	return query(db);
};

export const eventGet = (eventId: string) => {
	return doc(firestore, 'events', eventId);
};

export const eventsGetFromIds = (eventsId: string[]) => {
	return query(db, where(documentId(), 'in', eventsId));
};

import DocPicto from '../Assets/picto-docs.png';
import { getRequestWithToken } from './api.service';

export const cyberDocuments = [
	{
		id: '1',
		title: '1 - Informations générales',
		link: '1._Informations_generales_-_Formation_Cybersecurite_Niv._1.pdf',
		img: DocPicto,
		label: 'Document 1'
	},
	{
		id: '2',
		title: '2 - Mots de passe',
		link: '2._Mots_de_passe_-_Formation_Cybersecurite_Niv._1.pdf',
		img: DocPicto,
		label: 'Les bons reflexes RGPD'
	},
	{
		id: '3',
		title: '3 - Réseaux Wifi',
		link: '3._Reseaux_wifi_-_Formation_Cybersecurite_Niv._1.pdf',
		img: DocPicto
	},
	{
		id: '4',
		title: '4 -  USBs',
		link: '4._USB_-_Formation_Cybersecurite_Niv._1.pdf',
		img: DocPicto
	},
	{
		id: '5',
		title: '5 - Réseaux sociaux',
		link: '5._Reseaux_sociaux_-_Formation_Cybersecurite_Niv._1.pdf',
		img: DocPicto
	},
	{
		id: '6',
		title: '6 - Phishing',
		link: '6._Phishing_-_Formation_Cybersecurite_Niv._1.pdf',
		img: DocPicto
	},
	{
		id: '7',
		title: '7 - Hack Objets connectés',
		link: '7._Hack_objets_connectes_-_Formation_Cybersecurite_Niv._1.pdf',
		img: DocPicto
	},
	{
		id: '8',
		title: '8 - Accès logiciels',
		link: '8._Acces_logiciels_-_Formation_Cybersecurite_Niv._1.pdf',
		img: DocPicto
	}
];

export const getEventActivities = async (eventId: string) => {
	try {
		const results = await getRequestWithToken(`/events/${eventId}/activities`);
		// TODO: Treat the results if needed
		const activities = results.data.data.activities;
		return activities;
	} catch (error) {
		console.error('Error getting activities:', error);
		// Handle the error if necessary
		throw error; // Rethrow the error to the caller
	}
};
