import React, { useEffect } from 'react';
import {
	Checkbox,
	Button,
	LinearProgress,
	Stack,
	Typography
} from '@mui/material';
import {
	PackageManager,
	PackageManagerInstallOptions
} from '@yume-chan/android-bin';
import { WrapConsumableStream, WritableStream } from '@yume-chan/stream-extra';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import Head from 'next/head';
import { GLOBAL_STATE } from '../../state';
import { ProgressStream, createFileStream, pickFile } from '../../utils';

enum Stage {
	Uploading,
	Installing,
	Completed
}

interface Progress {
	filename: string;
	stage: Stage;
	uploadedSize: number;
	totalSize: number;
	value: number | undefined;
}

class InstallPageState {
	installing = false;

	progress: Progress | undefined = undefined;

	log = '';

	options: Partial<PackageManagerInstallOptions> = {
		bypassLowTargetSdkBlock: false
	};

	constructor() {
		makeAutoObservable(this, {
			progress: observable.ref,
			install: false,
			options: observable.deep
		});
	}

	install = async () => {
		const file = await pickFile({ accept: '.apk' });
		if (!file) {
			return;
		}

		runInAction(() => {
			this.installing = true;
			this.progress = {
				filename: file.name,
				stage: Stage.Uploading,
				uploadedSize: 0,
				totalSize: file.size,
				value: 0
			};
			this.log = '';
		});

		const pm = new PackageManager(GLOBAL_STATE.adb!);
		const start = Date.now();
		const log = await pm.installStream(
			file.size,
			createFileStream(file)
				.pipeThrough(new WrapConsumableStream())
				.pipeThrough(
					new ProgressStream(
						action(uploaded => {
							if (uploaded !== file.size) {
								this.progress = {
									filename: file.name,
									stage: Stage.Uploading,
									uploadedSize: uploaded,
									totalSize: file.size,
									value: (uploaded / file.size) * 0.8
								};
							} else {
								this.progress = {
									filename: file.name,
									stage: Stage.Installing,
									uploadedSize: uploaded,
									totalSize: file.size,
									value: 0.8
								};
							}
						})
					)
				)
		);

		const elapsed = Date.now() - start;
		await log.pipeTo(
			new WritableStream({
				write: action(chunk => {
					this.log += chunk;
				})
			})
		);

		const transferRate = (file.size / (elapsed / 1000) / 1024 / 1024).toFixed(2);
		this.log += `Install finished in ${elapsed}ms at ${transferRate}MB/s`;

		runInAction(() => {
			this.progress = {
				filename: file.name,
				stage: Stage.Completed,
				uploadedSize: file.size,
				totalSize: file.size,
				value: 1
			};
			this.installing = false;
		});
	};
}

const state = new InstallPageState();

const Install = () => {
	useEffect(() => {
		// Add any necessary cleanup or initialization code here
		return () => {
			// Cleanup code if needed
		};
	}, []);

	return (
		<Stack>
			<Stack direction='row'>
				<Button
					disabled={!GLOBAL_STATE.adb || state.installing}
					variant='contained'
					onClick={state.install}
				>
					Browse APK
				</Button>
			</Stack>

			{state.progress && (
				<div>
					<Typography variant='subtitle1'>{state.progress.filename}</Typography>
					<LinearProgress
						sx={{ width: 300 }}
						variant='determinate'
						value={state.progress.value}
					/>
					<Typography variant='body1'>{Stage[state.progress.stage]}</Typography>
				</div>
			)}

			{state.log && <pre>{state.log}</pre>}
		</Stack>
	);
};

export default observer(Install);
