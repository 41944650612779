import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {
	PackageManager,
	PackageManagerInstallOptions
} from '@yume-chan/android-bin';
import { GLOBAL_STATE } from '../../state';
import { ProgressStream, createFileStream, pickFile } from '../../utils';
import { WrapConsumableStream, WritableStream } from '@yume-chan/stream-extra';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';

const FileDownload: React.FC = () => {
	console.log('FileDownload');
	const downloadAPK = async () => {
		const apiURL = process.env.REACT_APP_API_PATH + '/files/apk.apk'; // Replace with the actual URL of your API route
		console.log('downloading apk', apiURL);

		try {
			const response = await fetch(apiURL);

			if (!response.ok) {
				throw new Error('Failed to fetch APK file');
			}

			// Get the content disposition header to determine the filename
			const contentDisposition = response.headers.get('content-disposition');
			const filenameMatch = contentDisposition?.match(/filename="(.+)"/);

			let filename = 'apk.apk'; // Default filename
			if (filenameMatch && filenameMatch[1]) {
				filename = filenameMatch[1];
			}

			// Create a blob from the response
			const blob = await response.blob();

			// Create a URL for the blob
			const blobURL = URL.createObjectURL(blob);

			// Create an anchor element for download
			const a = document.createElement('a');
			a.href = blobURL;
			a.download = filename;
			document.body.appendChild(a);

			// Trigger the click event to initiate download
			a.click();

			// Clean up the anchor element and revoke the blob URL
			document.body.removeChild(a);
			URL.revokeObjectURL(blobURL);
		} catch (error) {
			console.error('Error:', error);
		}
	};
	const downloadAPK2 = async () => {
		const apiURL = process.env.REACT_APP_API_PATH + '/files/apk.apk'; // Replace with the actual URL of your API route
		// const apiURL = process.env.REACT_APP_API_PATH + '/files/background.png'; // Replace with the actual URL of your API route

		console.log('downloading apk', apiURL);

		try {
			const response = await fetch(apiURL);

			if (!response.ok) {
				throw new Error('Failed to fetch APK file');
			}

			// Get the content disposition header to determine the filename
			const contentDisposition = response.headers.get('content-disposition');
			const filenameMatch = contentDisposition?.match(/filename="(.+)"/);

			let filename = 'apk.apk'; // Default filename
			if (filenameMatch && filenameMatch[1]) {
				filename = filenameMatch[1];
			}

			// Create a blob from the response
			const blob = await response.blob();
			console.log('blob', blob);

			// Store the blob in the cache
			const cacheName = 'apk-cache';
			const maxCacheSize = 500 * 1024 * 1024; // 500 MB storage quota
			const cache = await caches.open(cacheName);
			console.log('cache opened', cache);
			const cacheKey = 'apk.png'; // Replace with a meaningful cache key
			await cache.put(cacheKey, new Response(blob));
			console.log('things store in the cache');
			// You can now access the blob from the cache for subsequent use
			const cachedResponse = await cache.match(cacheKey);

			if (!cachedResponse) {
				throw new Error('Failed to retrieve cached APK file');
			}
			console.log('cache controlled', cachedResponse);

			// Create a blob from the cached response
			const cachedBlob = await cachedResponse.blob();

			// Create a URL for the cached blob
			const cachedBlobURL = URL.createObjectURL(cachedBlob);
			console.log('cache cachedBlobURL', cachedBlobURL);

			// Create an anchor element for download
			// const a = document.createElement('a');
			// a.href = cachedBlobURL;
			// a.download = filename;
			// document.body.appendChild(a);

			// // Trigger the click event to initiate download
			// a.click();

			// // Clean up the anchor element and revoke the blob URL
			// document.body.removeChild(a);
			// URL.revokeObjectURL(cachedBlobURL);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const downloadAPK3 = async (): Promise<void> => {
		const apiURL: string = process.env.REACT_APP_API_PATH + '/files/apk.apk'; // Replace with the actual URL of your API route
		console.log('downloading apk', apiURL);

		try {
			const response: Response = await fetch(apiURL);

			if (!response.ok) {
				throw new Error('Failed to fetch APK file');
			}

			// Get the content disposition header to determine the filename
			const contentDisposition: string | null = response.headers.get(
				'content-disposition'
			);
			const filenameMatch = contentDisposition?.match(/filename="(.+)"/);

			let filename = 'apk.apk'; // Default filename
			if (filenameMatch && filenameMatch[1]) {
				filename = filenameMatch[1];
			}

			// Create a blob from the response
			const blob: Blob = await response.blob();
			console.log('blob', blob);

			// 1. Open a connection to the IndexedDB database.
			const dbName = 'apk-db';
			const dbVersion = 2;

			const request: IDBOpenDBRequest = indexedDB.open(dbName, dbVersion);

			request.onerror = (event: Event) => {
				console.error(
					'Error opening IndexedDB:',
					(event.target as IDBOpenDBRequest).error
				);
			};

			request.onupgradeneeded = event => {
				const db = (event.target as IDBOpenDBRequest).result;

				// Check if the object store already exists; if not, create it
				if (!db.objectStoreNames.contains('files')) {
					const objectStore = db.createObjectStore('files', { autoIncrement: true });
				}
			};

			request.onsuccess = async event => {
				const db = (event.target as IDBOpenDBRequest).result;

				// 2. Check if the 'files' object store exists before using it.
				if (!db.objectStoreNames.contains('files')) {
					console.error("Object store 'files' does not exist.");
					return;
				}

				// 3. Use the object store in a transaction.
				const transaction = db.transaction('files', 'readwrite');
				const objectStore = transaction.objectStore('files');

				const requestAdd: IDBRequest<IDBValidKey> = objectStore.add(blob);

				requestAdd.onsuccess = async event => {
					const key: IDBValidKey = (event.target as IDBRequest<IDBValidKey>).result;
					console.log('apk stored in db', key);

					// Use the stored APK blob for installation
					const requestGet: IDBRequest<Blob | undefined> = objectStore.get(key);

					requestGet.onsuccess = async (event: Event) => {
						const apkBlob: Blob | undefined = (
							event.target as IDBRequest<Blob | undefined>
						).result;

						if (apkBlob) {
							console.log('apk is not null', apkBlob);
							// const file = await pickFile({ accept: '.apk' });
							// Install the APK file using the stored blob
							const apkFileName = 'app.apk'; // Change this to the desired APK file name
							const file = new File([apkBlob], apkFileName);

							const pm = new PackageManager(GLOBAL_STATE.adb!);

							try {
								console.log('trying install', pm);
								const start = Date.now();
								const log = await pm.installStream(
									apkBlob.size,
									createFileStream(file)
										.pipeThrough(new WrapConsumableStream())
										.pipeThrough(
											new ProgressStream(
												action(uploaded => {
													console.log('uploading', (uploaded / file.size) * 0.8);
												})
											)
										)
								);
							} catch (error) {
								console.log('error', error);
							}
						} else {
							console.error('APK file not found in IndexedDB');
						}
					};

					requestGet.onerror = (event: Event) => {
						console.error(
							'Error retrieving APK file from IndexedDB:',
							(event.target as IDBRequest).error
						);
					};
				};

				requestAdd.onerror = event => {
					console.error(
						'Error adding APK file to IndexedDB:',
						(event.target as IDBRequest).error
					);
				};
			};

			// request.onsuccess = (event: Event) => {
			// 	const db = (event.target as IDBOpenDBRequest).result;
			// 	// 3. Use the object store in a transaction.
			// 	const transaction = db.transaction('files', 'readwrite');
			// 	const objectStore = transaction.objectStore('files');

			// 	const requestAdd: IDBRequest<IDBValidKey> = objectStore.add(blob);

			// 	requestAdd.onsuccess = (event: Event) => {
			// 		console.log(
			// 			'APK file added to IndexedDB with key:',
			// 			(event.target as IDBRequest<IDBValidKey>).result
			// 		);

			// 		// 3. Retrieve APK file from IndexedDB.
			// 		const key: IDBValidKey = (event.target as IDBRequest<IDBValidKey>).result;

			// 		const requestGet: IDBRequest<Blob | undefined> = objectStore.get(key);

			// 		requestGet.onsuccess = (event: Event) => {
			// 			const apkBlob: Blob | undefined = (
			// 				event.target as IDBRequest<Blob | undefined>
			// 			).result;

			// 			if (apkBlob) {
			// 				// Create a URL for the APK blob
			// 				const apkBlobURL: string = URL.createObjectURL(apkBlob);
			// 				console.log('APK Blob URL:', apkBlobURL);

			// 				// Create an anchor element for download
			// 				const a: HTMLAnchorElement = document.createElement('a');
			// 				a.href = apkBlobURL;
			// 				a.download = filename;
			// 				document.body.appendChild(a);

			// 				// Trigger the click event to initiate download
			// 				a.click();

			// 				// Clean up the anchor element and revoke the blob URL
			// 				document.body.removeChild(a);
			// 				URL.revokeObjectURL(apkBlobURL);
			// 			} else {
			// 				console.error('APK file not found in IndexedDB');
			// 			}
			// 		};

			// 		requestGet.onerror = (event: Event) => {
			// 			console.error(
			// 				'Error retrieving APK file from IndexedDB:',
			// 				(event.target as IDBRequest).error
			// 			);
			// 		};
			// 	};

			// 	requestAdd.onerror = (event: Event) => {
			// 		console.error(
			// 			'Error adding APK file to IndexedDB:',
			// 			(event.target as IDBRequest).error
			// 		);
			// 	};
			// };
		} catch (error) {
			console.error('Error:', error);
		}
	};

	return (
		<Button
			color='error'
			sx={{
				typography: 'h4',
				fontSize: '0.8rem',
				borderRadius: 5,
				textColor: '#17295C',
				color: 'primary',
				backgroundColor: 'primary',
				height: '2.5rem',
				mt: 2,
				px: 2,
				width: '50%'
			}}
			onClick={downloadAPK3}
			endIcon={<DownloadIcon />}
		>
			Télécharger APK
		</Button>
	);
};

export default FileDownload;
