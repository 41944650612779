import { useSelector } from 'react-redux';
import { selectPlayerEvents, selectEvents } from '../../../app/dataSlice';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Divider,
	Grid,
	Typography
} from '@mui/material';
import { CardLabel } from '../../../Components/Cards/TrainingCard/CardLabel';
import headset from '../../../Assets/htc_headset.png';
import tablet from '../../../Assets/tablet.png';
import CloseIcon from '@mui/icons-material/Close';
import { Connect } from '../../../Components/Adb/Connect';
import Install from '../../../Components/Adb/Install';
import { GLOBAL_STATE } from '../../../state';
import FileDownload from '../../../Components/Files/FileDownload';

export const UserDashboard = () => {
	const playerEvents = useSelector(selectPlayerEvents);
	const events = useSelector(selectEvents);
	const playerEventsInfo =
		events?.filter(objA =>
			playerEvents?.some(objB => objB.eventId === objA.id)
		) ?? [];
	return (
		<div className='flex flex-col lg:flex-col w-full'>
			<div className='w-full pl-8 pr-20'>
				<h2>Accueil - User</h2>
				<Divider />
				<div>Vous n'avez pas pas de licence valide</div>
			</div>
			<div className='w-full px-8 flex flex-col lg:flex-row'>
				<div className='w-1/2 p-2 cursor-pointer self-center'>
					<Card
						sx={{
							margin: 'auto',
							borderRadius: 5,
							backgroundColor: 'white',
							height: '15rem'
						}}
					>
						<CardContent sx={{ height: '100%' }}>
							<Grid container>
								<img
									src={headset}
									alt={'vr-headset'}
									style={{
										width: '10rem',
										margin: 'auto',
										maxHeight: '6rem'
									}}
								/>
								<Grid container item xs={12} sx={{ flexDirection: 'column' }}>
									<Box sx={{ color: 'white', textAlign: 'center' }}>
										<Typography
											variant='h5'
											component='div'
											sx={{ pb: 1, fontWeight: 'bold' }}
										>
											{'Lunettes'}
										</Typography>
									</Box>
								</Grid>
								<Grid
									item
									xs={12}
									container
									direction='column'
									sx={{
										display: 'flex',
										px: 1,
										alignItems: 'center'
									}}
								>
									<Button
										color='error'
										sx={{
											typography: 'h4',
											fontSize: '0.8rem',
											borderRadius: 5,
											textColor: '#17295C',
											color: 'primary',
											backgroundColor: '#FFE7E9',
											height: '2.5rem',
											mt: 2,
											px: 2,
											width: '50%'
										}}
										endIcon={<CloseIcon />}
									>
										Non Connecté
									</Button>
								</Grid>
								<Divider sx={{ my: 3, width: '20%', mx: 'auto' }} />
							</Grid>
						</CardContent>
					</Card>
				</div>
				<div className='w-1/2 p-8 cursor-pointer self-center'>
					<Card
						sx={{
							margin: 'auto',
							borderRadius: 5,
							backgroundColor: 'white',
							height: '15rem'
						}}
					>
						<CardContent sx={{ height: '100%' }}>
							<Grid container>
								<img
									src={tablet}
									alt={'tablet'}
									style={{
										maxHeight: '6rem',
										margin: 'auto'
									}}
								/>
								<Grid container item xs={12} sx={{ flexDirection: 'column' }}>
									<Box sx={{ color: 'white', textAlign: 'center' }}>
										<Typography
											variant='h5'
											component='div'
											sx={{ pb: 1, fontWeight: 'bold' }}
										>
											{'Tablette'}
										</Typography>
									</Box>
								</Grid>
								<Grid
									item
									container
									xs={12}
									direction='column'
									sx={{
										display: 'flex',
										px: 1,
										alignItems: 'center'
									}}
								>
									<Button
										color='error'
										sx={{
											typography: 'h4',
											fontSize: '0.8rem',
											borderRadius: 5,
											textColor: '#17295C',
											color: 'primary',
											backgroundColor: '#FFE7E9',
											height: '2.5rem',
											mt: 2,
											px: 2,
											width: '50%'
										}}
										endIcon={<CloseIcon />}
									>
										Non Connecté
									</Button>
								</Grid>
								<Divider sx={{ my: 3, width: '20%', mx: 'auto' }} />
							</Grid>
						</CardContent>
					</Card>
				</div>
			</div>
			<Connect />
			<Install />
			<div className='w-full px-8 flex flex-col lg:flex-row'>
				<div className='w-1/2 p-2 cursor-pointer self-center'>
					{/* <FileUpload></FileUpload> */}
					<Card
						sx={{
							margin: 'auto',
							borderRadius: 5,
							backgroundColor: 'white',
							height: '15rem'
						}}
					>
						<CardContent sx={{ height: '100%' }}>
							<Grid container>
								<Grid
									item
									container
									xs={12}
									direction='column'
									sx={{
										display: 'flex',
										px: 1,
										alignItems: 'center'
									}}
								>
									<FileDownload></FileDownload>
								</Grid>
								<Divider sx={{ my: 3, width: '20%', mx: 'auto' }} />
							</Grid>
						</CardContent>
					</Card>
				</div>
			</div>
		</div>
	);
};
