import { Timestamp } from 'firebase/firestore';

const toHHMMSS = (val: any) => {
	if (val == null) return;
	const sec_num = parseInt(val, 10); // don't forget the second param
	let hours: any = Math.floor(sec_num / 3600);
	let minutes: any = Math.floor((sec_num - hours * 3600) / 60);
	let seconds: any = sec_num - hours * 3600 - minutes * 60;

	if (hours < 10) {
		hours = '0' + hours;
	}
	if (minutes < 10) {
		minutes = '0' + minutes;
	}
	if (seconds < 10) {
		seconds = '0' + seconds;
	}
	return hours + ':' + minutes + ':' + seconds;
};

const toMMSS = (val: any) => {
	if (val == null) return;
	const sec_num = parseInt(val, 10); // don't forget the second param
	let hours: any = Math.floor(sec_num / 3600);
	let minutes: any = Math.floor((sec_num - hours * 3600) / 60);
	let seconds: any = sec_num - hours * 3600 - minutes * 60;

	if (hours < 10) {
		hours = '0' + hours;
	}
	if (minutes < 10) {
		minutes = '0' + minutes;
	}
	if (seconds < 10) {
		seconds = '0' + seconds;
	}
	return minutes + ':' + seconds;
};

function TimestampDisplay(time: any) {
	const timestamp = time;
	try {
		// Convert the timestamp to a JavaScript Date object
		const dateObj = new Date(timestamp);
		if (isNaN(Date.parse(timestamp))) return '-';

		// Extract the day, month, and year from the date object
		const day = dateObj.getDate();
		const month = dateObj.getMonth() + 1; // Add 1 because getMonth() returns 0-based months
		const year = dateObj.getFullYear();

		// Construct the date string in the desired format
		const dateStr = `${day} / ${month} / ${year}`;

		// Render the date string
		return dateStr;
	} catch {
		return '-';
	}
}

export { toHHMMSS, toMMSS, TimestampDisplay };
