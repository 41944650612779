import urlcat from 'urlcat';
import { postRequestJSON } from './api.service';
import { setItem } from '../lib/docCookies';
import Cookies from 'js-cookie';

/* GENERIC METHODS */
/**
 * @name commonRequestHeader
 * Define the common request header
 */

const baseUrl = '/auth/';
/* GET METHODS */

export const SignIn = async (email?: string, password?: string) => {
	const url = baseUrl + 'login';
	return postRequestJSON(url, {
		email: email,
		password: password
	});
};

/* POST METHODS */
