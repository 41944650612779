import { Timestamp } from 'firebase/firestore';

export enum ITRole {
	ADMIN = 'admin',
	USER = 'user',
	TRAINING_CENTER = 'trainingcenter',
	MULTILICENSES = 'multilicenses'
}

export interface ITUserData {
	id: string;
	email?: string;
	firstName?: string;
	lastName?: string;
	eventId?: string;
	teamId?: string;
	modifiedAt?: Timestamp;
	createdAt?: Timestamp;
	role?: ITRole;
	licencesNumber?: number;
	licencesUsed?: number;
	company?: string;
	job?: string;
}
