import {
	Alert,
	Box,
	TextField,
	Typography,
	Checkbox,
	FormGroup,
	FormControlLabel,
	IconButton,
	InputAdornment
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	SendSignInLinkToEmail,
	HandleSignInWithEmailLink
} from '../../services/auth.service';
import { MyButton } from '../../Components/Atoms/MyButton/MyButton';
import { PageCentered } from '../Page/PageCentered';
import background from '../../Assets/background.png';
import backgroundLogin from '../../Assets/login-background.png';
import Link from '@mui/material/Link';
import { askLinkAnalytics } from '../../services/analytics.service';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { SignIn } from '../../services/authentification.service';
import Cookies from 'js-cookie';
import { UserContext } from '../../app/UserContextProvider';

interface AuthentificationPageProps {
	prop1?: string;
}

const CGU = "J'accepte les";
const cookiesText = "J'accepte l'utilisation des ";
const errorMsgCGU = 'Veuillez accepter les CGU';
const defautlError = 'Veuillez accepter les conditions';
const noInputError = 'Veuillez entrer votre email';
const emailError = 'Veuillez entrer un email valide';
const emailSent = 'un lien de connexion vous a été envoyé par email';
const loginError = 'Veuillez vérifier vos identifiants de connexion';
const textDescription =
	'Veuillez entrer votre mail pour recevoir un lien de connexion';

export const AuthentificationPage = ({
	prop1,
	...props
}: AuthentificationPageProps) => {
	const navigate = useNavigate();
	const [authing, setAuthing] = useState<boolean | undefined>(undefined);
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [isLogInWithPassword, SetLogInWithPassword] = useState<boolean>(true);
	const [isCGUChecked, setIsCGUChecked] = useState(true);
	const [isCookiesChecked, setIsCookiesChecked] = useState(true);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [errorMsg, setErrorMsg] = useState<string>(defautlError);
	const [showPassword, setShowPassword] = useState(false);
	const context = useContext(UserContext);

	const cookieDuration = 7;

	const handleCheckboxCGUChange = event => {
		setIsCGUChecked(event.target.checked);
		setError(false);
	};

	const handleCheckboxCookiesChange = event => {
		setIsCookiesChecked(event.target.checked);
		setError(false);
	};

	useEffect(() => {
		HandleSignInWithEmailLink();
	}, []);

	const HandleLogin = () => {
		if (isLogInWithPassword) {
			signInWithEmail();
		} else {
			askSignInEmail();
			setSuccess(true);
			setTimeout(() => {
				setSuccess(false);
			}, 10000); // remove error message after 10 seconds
			askLinkAnalytics('login');
		}
	};

	const signInWithEmail = () => {
		SignIn(email, password)
			.then(result => {
				if (result.data?.data?.token) {
					Cookies.set('userToken', result.data.data.token, {
						expires: cookieDuration
					});
					context?.asyncGetUserInfo().then(() => {
						navigate('/');
					});
				} else {
					DisplayTemporaryErrorMessage(loginError);
				}
			})
			.catch(error => {
				console.error(`Error: ${error}`);
				DisplayTemporaryErrorMessage(error);
			});
	};

	const askSignInEmail = () => {
		SendSignInLinkToEmail(email);
	};

	const HandleLogInWithPassword = () => {
		SetLogInWithPassword(!isLogInWithPassword);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (email.length < 1) {
			setError(true);
			setErrorMsg(noInputError);
			return;
		}
		if (!isValidEmail(email)) {
			setError(true);
			setErrorMsg(emailError);
			return;
		}
		if (!isCGUChecked || !isCookiesChecked) {
			setError(true);
			setErrorMsg(defautlError);
			return;
		}
		HandleLogin();
	};

	function displayError(errorMessage) {
		setError(true);
		setErrorMsg(errorMessage);
	}

	function DisplayTemporaryErrorMessage(errorMessage, timing = 10000) {
		displayError(errorMessage);
		setTimeout(() => {
			setError(false);
		}, timing); // remove error message after timing milliseconds
	}

	function isValidEmail(email) {
		// regular expression for email validation
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	const openPdf = () => {
		//TODO to add
		// window.open('/CGU.pdf', '_blank');
	};

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	//Redirect to the authentification page if logged in
	// if (AuthGetUser()) {
	// 	navigate('/');
	// }

	return (
		<PageCentered
			bgColor='primary.main'
			bgImg={background}
			bgImgSecond={backgroundLogin}
			centeredContent={true}
		>
			<Box
				sx={{
					margin: 'auto',
					boxSizing: 'border-box',
					display: 'flex',
					alignItems: 'center',
					verticalAlign: 'middle',
					height: '100%',
					p: 2,
					pt: 1,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					overflow: 'hidden'
				}}
			>
				<Box
					sx={{
						width: '100%',
						flexDirection: 'column',
						display: 'flex',
						alignItems: 'center',
						overflow: 'hidden'
					}}
				>
					{authing === false && (
						<Alert severity='error'>
							Cet email n'existe pas. Veuillez contacter l'administrateur de
							l'événement.
						</Alert>
					)}
					<Box sx={{ typography: 'h1', marginBottom: '2rem', marginTop: '1rem' }}>
						Connexion
					</Box>
					<Box sx={{ width: '80%', textAlign: 'center' }}>
						{!isLogInWithPassword && <Typography>{textDescription}</Typography>}
						<TextField
							autoFocus
							fullWidth
							margin='dense'
							id='name'
							label='Adresse email'
							type='email'
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
					</Box>
					{isLogInWithPassword && (
						<Box sx={{ width: '80%', maxWidth: '80%' }}>
							<TextField
								autoFocus
								fullWidth
								margin='dense'
								id='password'
								label='password'
								type={showPassword ? 'text' : 'password'}
								value={password}
								onChange={handlePasswordChange}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton onClick={handleTogglePasswordVisibility}>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</Box>
					)}
					<Box>
						<Typography>
							<Link href='#'>Mot de passe oublié ?</Link>
						</Typography>
					</Box>
					<Box sx={{ width: '80%', maxWidth: '90%' }}>
						<form onSubmit={handleSubmit}>
							<FormGroup
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-start'
								}}
							>
								<FormControlLabel
									control={
										<Checkbox checked={isCGUChecked} onChange={handleCheckboxCGUChange} />
									}
									label={
										<Typography>
											{CGU}{' '}
											<Link href='#' onClick={openPdf}>
												CGU
											</Link>
										</Typography>
									}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={isCookiesChecked}
											onChange={handleCheckboxCookiesChange}
										/>
									}
									label={
										<Typography>
											{cookiesText} <Link href='#'>cookies</Link>
										</Typography>
									}
								/>
								{error && <Typography color='error'>{errorMsg}</Typography>}
								{success && (
									<Typography fontSize='0.7rem' color='green'>
										{emailSent}
									</Typography>
								)}
							</FormGroup>
							<Box sx={{ textAlign: 'center' }}>
								<MyButton
									variant='contained'
									type='submit'
									sx={{
										width: '80%',
										borderRadius: 10,
										height: '3rem',
										marginTop: {
											xs: '1rem',
											sm: '1.3rem',
											md: '1.7rem'
										},
										marginBottom: '0.5rem'
									}}
								>
									{isLogInWithPassword
										? "S'identifier"
										: 'Demander un lien de connexion'}
								</MyButton>
							</Box>
						</form>
					</Box>
				</Box>
			</Box>
		</PageCentered>
	);
};
