import { useSelector } from 'react-redux';
import { selectPlayerEvents, selectEvents } from '../../../app/dataSlice';
import ContactUs from './contact-us.jpg';
import { AdminOverviewCard } from './AdminOverviewCard';
import { useContext } from 'react';
import { UserContext } from '../../../app/UserContextProvider';
import { ITRole } from '../../../types/user.type';
import { Divider } from '@mui/material';

export const AdminDashboard = () => {
	const context = useContext(UserContext);
	const user = context?.user;
	const isAdmin = user?.role == ITRole.ADMIN;
	if (!isAdmin) return <div>Vous n'êtes pas administrateur</div>;
	const playerEvents = useSelector(selectPlayerEvents);
	// TODO: select the events that the user is admin of
	const events = useSelector(selectEvents);
	const playerEventsInfo =
		events?.filter(objA =>
			playerEvents?.some(objB => objB.eventId === objA.id)
		) ?? [];
	return (
		<div className='flex flex-col lg:flex-col'>
			<div className='w-2/3 mx-8'>
				<h2>Accueil</h2>
				<Divider />
				{playerEventsInfo?.length > 0 ? (
					playerEventsInfo.map(e => (
						<AdminOverviewCard key={`adminTrainingCard-${e.id}`} event={e} />
					))
				) : (
					<div>Vous n'avez pas pas de licence valide</div>
				)}
			</div>
		</div>
	);
};
