import React from 'react';
import { Box, display } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import theme, { secondaryColor } from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../../Assets/logo-ptitbout-white.png';

import {
	selectSidebarVisibility,
	updateSidebarVisibility
} from '../../app/UISlice';
import { IconButton, Stack } from '@mui/material';
interface PageProps {
	bgColor?: string;
	bgImg?: any;
	bgImgSecond?: any;
	children: JSX.Element | JSX.Element[];
	centeredContent?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const PageCentered = ({
	bgColor = 'background.paper',
	bgImg,
	bgImgSecond = '',
	centeredContent = false,
	children,
	...props
}: PageProps) => {
	const sidebarVisibility = useSelector(selectSidebarVisibility);
	const dispatch = useDispatch();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Box
			sx={{
				left: '0',
				right: '0',
				top: '0',
				bottom: '0',
				position: 'absolute',
				overflow: 'hidden',
				display: 'flex',
				flexDirection: 'column',
				maxWidth: '100%',
				boxShadow: '0 0 5px 5px rgba(0,0,0,0.1)',
				backgroundImage: `url(` + bgImg + `)`,
				backgroundSize: `cover`,
				alignItems: 'center'
			}}
		>
			<Box
				sx={{
					left: '0',
					right: '0',
					top: '0',
					bottom: '0',
					position: 'absolute',
					overflow: 'hidden',
					display: 'flex',
					flexDirection: 'column',
					maxWidth: '98%',
					maxHeight: '98%',
					m: 'auto',
					backgroundImage: `url(` + bgImgSecond + `)`,
					backgroundSize: `contain`,
					borderRadius: '1rem',
					alignItems: 'center'
				}}
			>
				<Stack
					flexDirection='column'
					sx={{
						alignItems: 'center',
						height: '70%',
						m: 'auto'
					}}
				>
					<Box
						component='img'
						alt="p'tit bout logo"
						src={logo}
						sx={{
							maxHeight: '20%',
							justifyContent: 'center',
							pb: 2,
							opacity: 1
						}}
					/>
					<Box
						sx={{
							maxWidth: '750px',
							width: '400px',
							height: '70%',
							overflow: 'hidden',
							margin: 'auto',
							backgroundColor: 'white',
							borderRadius: 3,
							boxShadow: `1px 5px 7px 2px rgba(0,0,0,0.2)`,
							display: 'grid',
							gridTemplate: 'minmax(0,1fr)'
						}}
					>
						<Box
							className='content'
							sx={{
								position: 'relative',
								overflow: 'hidden',
								display: 'flex'
							}}
						>
							<Box
								sx={{
									position: 'relative',
									height: '100%',
									minHeight: '100%',
									flex: '1'
								}}
								className='content'
							>
								<Box
									sx={{
										width: '100%',
										height: '100%',
										boxSizing: 'border-box',
										display: centeredContent ? 'flex' : 'block',
										alignItems: centeredContent ? 'center' : 'unset',
										justifyContent: centeredContent ? 'center' : 'unset'
									}}
								>
									{children}
								</Box>
							</Box>
						</Box>
					</Box>
				</Stack>
			</Box>
		</Box>
	);
};
