import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

interface MyButtonProps extends ButtonProps {
	variant: 'contained' | 'outlined' | 'text';
}

const myButtonStyles = makeStyles(t => ({
	root: {}
}));

export const MyButton = ({
	variant = 'contained',
	children,
	...props
}: MyButtonProps) => {
	const classes = myButtonStyles();
	return (
		<Button
			sx={{
				borderRadius: 2,
				boxSizing: 'border-box',
				'&.MuiButton-': {
					'&sizeSmall': {
						py: 0.5,
						px: 1.5,
						fontSize: '0.6rem'
					},
					'&outlined': {
						border: 'solid 1px white',
						color: 'white'
					}
				},
				'&.MuiButton-contained': {
					'&Success': {
						backgroundColor: 'success.main',
						color: 'white',
						'&:hover': {
							background: 'white',
							color: 'success.main'
						}
					},
					'&Primary': {
						'&:hover': {
							background: 'white',
							color: 'primary.main'
						}
					}
				},
				'&sizeSmall': {
					p: 0
				}
			}}
			variant={variant}
			{...props}
		>
			{children}
		</Button>
	);
};
