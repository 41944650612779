import { useSelector } from 'react-redux';
import { Header } from '../../Components/Header/Header';
import { NavigationBar } from '../../Components/Includes/Sidebar/NavigationBar';
import { selectDisplayDashboardState } from '../../app/dataSlice';
import background from '../../Assets/background.png';

interface PageProps {
	children: JSX.Element | JSX.Element[];
	unityUnloadFunction?: () => Promise<void>;
}
/**
 * Primary UI component for user interaction
 */
export const Page = ({ children, unityUnloadFunction }: PageProps) => {
	const displayDashboardState = useSelector(selectDisplayDashboardState);
	return (
		<div
			className='h-screen flex justify-center items-center'
			style={{
				backgroundImage: `url(${background})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center'
			}}
		>
			<div className='bg-gray-100 items-center flex flex-row h-screen overflow-y-hidden h-[96vh] w-[96vw] rounded-md'>
				<div
					className='bg-white w-64 h-full z-10 rounded-md shadow-lg'
					style={{
						boxShadow: '10px 0px 20px rgba(0, 0, 0, 0.10)'
					}}
				>
					<NavigationBar />
				</div>
				<div
					className='rounded-md my-8 h-full z-10 w-full pt-10'
					style={{ maxWidth: 'calc(100% - 16rem)' }}
				>
					<main className='h-full rounded-lg overflow-y-scroll pb-24'>
						{children}
					</main>
				</div>
			</div>
		</div>
	);
};
