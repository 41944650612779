import { Button, ListItemIcon } from '@mui/material';

interface CTAProps {
	variant:
		| 'primary'
		| 'secondary'
		| 'tertiary'
		| 'outlined'
		| 'outlined-dark'
		| 'text';
	title: string;
	onClick: (e) => void;
	icon?: React.ReactNode;
	iconPosition?: 'start' | 'end';
	classes?: string;
	noPadding?: boolean;
}
export const CTA = ({
	variant,
	title,
	icon,
	iconPosition,
	classes,
	noPadding,
	onClick
}: CTAProps) => {
	const ic = icon && (
		<div
			className={`mt-1 h-7 cursor-pointer ${
				iconPosition === 'end' ? 'ml-auto' : ''
			}`}
		>
			<ListItemIcon
				sx={{
					justifyContent: 'center',
					color: variant == 'outlined-dark' ? '#17295C' : 'white',
					cursor: 'pointer'
				}}
			>
				{icon}
			</ListItemIcon>
		</div>
	);
	let variantClass = '';
	let variantTextClass = 'text-white';
	switch (variant) {
		case 'primary':
			variantClass = 'border-2 border-accent bg-accent';
			break;
		case 'secondary':
			variantClass = 'border-2 border-primary bg-primary';
			break;
		case 'tertiary':
			variantClass = '';
			variantTextClass = 'text-primary font-semibold group-hover:text-white';
			break;
		case 'outlined':
			variantClass = 'border-2 border-white';
			break;
		case 'outlined-dark':
			variantClass = 'border-2 border-primary';
			variantTextClass = 'text-primary';
			break;
	}
	return (
		<div
			onClick={onClick}
			className={`group flex flex-row justify-items-center
			h-10 py-0.5 mx-4 my-2 rounded-lg cursor-pointer
			hover:bg-primary-light active:border-white drop-shadow
			${variantClass} 
			${classes ?? ''}`}
		>
			{iconPosition === 'start' && ic}
			<span
				className={`my-auto text-xs font-bold ${
					ic && !variant.includes('outlined') ? 'font-semibold' : 'font-medium'
				} ${variantTextClass} ${noPadding ? '' : 'px-4'}`}
			>
				{title}
			</span>
			{iconPosition === 'end' && ic}
		</div>
	);
};
