import {
	collection,
	doc,
	query,
	where,
	addDoc,
	Timestamp
} from 'firebase/firestore';
import { firestore } from '../firebase';
import {
	addUserApi,
	getRequestWithToken,
	getTokenApi,
	getUsersWithToken
} from './api.service';
import { updateUser } from '../app/dataSlice';

const db = collection(firestore, 'users');
const dbTeam = collection(firestore, 'teams');

export const userGetAll = () => {
	return query(db);
};
export const userGetWithEmail = (email: string) => {
	return query(db, where('email', '==', email));
};

export const userGet = (userId: string) => {
	return doc(firestore, 'users', userId);
};

export const addUser = async (userEmail: string) => {
	// console.log('creating user for email ' + userEmail);
	const teamNameSplit = userEmail.split('@');
	const teamName = teamNameSplit[0] || '';
	const timeNow = new Timestamp(new Date().getTime() / 1000, 0);
	const user = await addDoc(db, {
		email: userEmail,
		firstName: teamName,
		lastName: '',
		createdAt: new Timestamp(new Date().getTime() / 1000, 0),
		modifiedAt: new Timestamp(new Date().getTime() / 1000, 0),
		activated: true
	});

	const training = [];

	const team = await addDoc(dbTeam, {
		eventId: process.env.REACT_APP_EVENT_ID, //TODO update this dynamically
		name: teamName,
		avatar: '',
		createdAt: new Timestamp(new Date().getTime() / 1000, 0),
		modifiedAt: new Timestamp(new Date().getTime() / 1000, 0),
		activities: training,
		points: 0,
		isTeamNameSet: false,
		teamSlogan: '',
		teamValues: [],
		players: [user.id]
	});
};

export const addUserUsingApi = async (
	userEmail: string,
	trainings: string[] = [],
	endDate?: Date,
	role?: string,
	licencesNumber?: number,
	createdBy?: string
) => {
	// console.log('creating user for email ' + userEmail);
	const teamNameSplit = userEmail.split('@');
	const teamName = teamNameSplit[0] || '';
	const timeNow = new Timestamp(new Date().getTime() / 1000, 0);
	return await addUserApi(
		userEmail,
		trainings,
		endDate,
		role,
		licencesNumber,
		createdBy
	)
		.then(results => {
			//TODO treat the results
			return results.data.status;
		})
		.catch(error => {
			console.error('Error during service worker registration:', error);
		});
};

export const getUserToken = async (
	userEmail: string | undefined,
	userId: string | undefined
) => {
	// console.log('creating user for email ' + userEmail);
	return await getTokenApi(userEmail || '', userId || '')
		.then(results => {
			//TODO treat the results
			return results.data;
		})
		.catch(error => {
			console.error('Error during service worker registration:', error);
		});
};

export const getUsers = async token => {
	// console.log('creating user for email ' + userEmail);
	return await getUsersWithToken(token)
		.then(results => {
			//TODO treat the results
			return results;
		})
		.catch(error => {
			console.error('Error:', error);
		});
};

export const addUserTraining = async (
	userEmail: string,
	trainings: string[] = []
) => {
	const teamNameSplit = userEmail.split('@');
	const teamName = teamNameSplit[0] || '';
	const timeNow = new Timestamp(new Date().getTime() / 1000, 0);

	return await addUserApi(userEmail, trainings)
		.then(results => {
			//TODO treat the results
			return results.data.status;
		})
		.catch(error => {
			console.error('Error during service worker registration:', error);
		});
};

export const getUsersAssociated = async userId => {
	return query(db, where('relatedTo', '==', userId));
};
