import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	Route,
	Routes,
	useNavigate,
	Outlet,
	useLocation
} from 'react-router-dom';
import './App.css';
import {
	updateDisplayActivity,
	updateDisplayDashboard,
	updateUnityDisplay
} from './app/dataSlice';
import theme from './theme';
import { AuthentificationPage } from './Pages/AuthentificationPage/AuthentificationPage';
import { SettingsPage } from './Pages/SettingsPage/SettingsPage';
import { ForgotPasswordPage } from './Pages/PasswordPage/ForgotPasswordPage';
import { ResetPasswordPage } from './Pages/PasswordPage/ResetPasswordPage';
import Cookies from 'js-cookie';
import { UserContextProvider } from './app/UserContextProvider';
import { DashboardPage } from './Pages/DashboardPage/DashboardPage';

const initScreenResize = () => {
	const appHeight = () => {
		const doc = document.documentElement;
		doc.style.setProperty('--app-height', `${window.innerHeight}px`);
	};
	window.addEventListener('resize', appHeight);
	appHeight();
};

const App = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	useEffect(() => {
		initScreenResize();
	}, []);

	useEffect(() => {
		const isAuth = !!Cookies.get('userToken');
		if (isAuth) {
			if (
				location.pathname == '/login' ||
				location.pathname == '/' ||
				location.pathname == '/login-token'
			) {
				navigate('/');
				dispatch(updateUnityDisplay(false));
				dispatch(updateDisplayDashboard(true));
				dispatch(updateDisplayActivity(false));
			} else if (location.pathname == '/login-experience') {
				dispatch(updateUnityDisplay(true));
				dispatch(updateDisplayDashboard(false));
				navigate('/experience/cyber');
			}
		} else {
			if (
				location.pathname != '/reset-password' &&
				location.pathname != '/forgot-password' &&
				location.pathname != '/login-experience' &&
				location.pathname != '/login-token' &&
				location.pathname != '/experience/test'
			)
				navigate('/login');
		}
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<Box />}>
				<UserContextProvider>
					<Box sx={{ bgcolor: 'white' }} className={`App`}>
						<Routes>
							<Route path='/' element={<DashboardPage isAdminDashboard={false} />} />
							<Route path='/admin' element={<DashboardPage isAdminDashboard />} />
							<Route path='/login' element={<AuthentificationPage />} />
							<Route path='/settings' element={<SettingsPage />} />
							<Route path='/forgot-password' element={<ForgotPasswordPage />} />
							<Route path='/reset-password' element={<ResetPasswordPage />} />
						</Routes>
						<Outlet />
					</Box>
				</UserContextProvider>
			</Suspense>
		</ThemeProvider>
	);
};

export default App;
