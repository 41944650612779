import {
	collection,
	doc,
	query,
	where,
	addDoc,
	Timestamp
} from 'firebase/firestore';
import { firestore } from '../firebase';
import UAParser from 'ua-parser-js';

const db = collection(firestore, 'analytics');

// Define a function to extract device type and operating system from user agent string
function parseUserAgent(userAgent) {
	const parser = new UAParser();
	parser.setUA(userAgent);
	const result = parser.getResult();
	const deviceType = result.device.type || '';
	const osName = result.os.name || '';
	const browserName = result.browser.name || '';
	const browserVersion = result.browser.version || '';
	return { deviceType, osName, browserName, browserVersion };
}

export const analyticsGetAll = () => {
	return query(db);
};
export const getEventAnalytics = (eventId: string) => {
	return query(db, where('eventId', '==', eventId));
};

export const getUserAnalytics = (userId: string) => {
	return doc(firestore, 'userId', userId);
};

export const getTeamAnalytics = (teamId: string) => {
	return doc(firestore, 'teamID', teamId);
};

export const askLinkAnalytics = async (eventId: string) => {
	// Call the parseUserAgent function whenever a user accesses the React JS application
	const userAgent = navigator.userAgent;
	const parsedAgent = parseUserAgent(userAgent);
	await addDoc(db, {
		eventId: eventId,
		status: 'askLink',
		ts: new Timestamp(new Date().getTime() / 1000, 0),
		connectionInfo: parsedAgent
	});
};

export const addTeamConnectionAnalytics = async (
	eventId: string,
	userId: string
) => {
	// Call the parseUserAgent function whenever a user accesses the React JS application
	const userAgent = navigator.userAgent;
	const parsedAgent = parseUserAgent(userAgent);
	await addDoc(db, {
		eventId: eventId,
		userId: userId,
		status: 'connection',
		ts: new Timestamp(new Date().getTime() / 1000, 0),
		connectionInfo: userAgent
	});
};

export const addTeamLaunchAnalytics = async (
	eventId: string,
	teamId: string
) => {
	await addDoc(db, {
		eventId: eventId,
		teamID: teamId,
		status: 'launchGame',
		ts: new Timestamp(new Date().getTime() / 1000, 0)
	});
};
