import { GridProps } from '@mui/material';
import { ITEventData } from '../../../types/event.type';
import { TimestampDisplay } from '../../../lib/Utils';
import { CTA } from '../../../Components/Atoms/MyButton/CTA';
import { useNavigate } from 'react-router-dom';
import { CardLabel } from '../../../Components/Cards/TrainingCard/CardLabel';

interface TrainingOverviewCardProps extends GridProps {
	event: ITEventData;
}

export const AdminOverviewCard = ({ event }: TrainingOverviewCardProps) => {
	const navigate = useNavigate();
	const name = event?.name || 'no name';
	const description = event?.description || '';
	const startAt = event?.startAt;

	return (
		<div className='flex flex-row drop-shadow items-center rounded-lg overflow-hidden max-w-full w-full p-2 mb-4 bg-gray'>
			<img
				src={event?.branding?.eventLogo || event?.branding?.loginImg}
				alt={event?.name}
				className='rounded-lg object-contain h-36 w-36'
			/>
			<div className='grow'>
				<div className='max-w-4/5'>
					<h3 className='ml-0'>{name}</h3>
					<p className='pb-4 text-sm'>{description}</p>
				</div>
				<div className='grid grid-cols-4 w-full pb-2 -ml-2'>
					<CardLabel
						label=''
						labelValue={`${TimestampDisplay(startAt)}`}
						isSmall
						labelColor='dark'
					/>
					<CardLabel label='Cours' labelValue={`${1}`} labelColor='dark' />
					<CardLabel label='Quizz' labelValue={`${1}`} labelColor='dark' />
					<CTA
						onClick={() => {
							navigate('/admin/event/' + event.id);
						}}
						// icon={<ArrowRightIcon />}
						// iconPosition='end'
						variant='primary'
						title='Administrer'
						classes='ml-auto my-0 h-8'
					/>
				</div>
			</div>
		</div>
	);
};
