import axios from 'axios';
import urlcat from 'urlcat';
import Cookies from 'js-cookie';
/* GENERIC METHODS */
/**
 * @name commonRequestHeader
 * Define the common request header
 */
const commonRequestHeader = {
	'Content-Type': 'application/x-www-form-urlencoded'
};

axios.defaults.headers.post['Content-Type'] = 'application/json';

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_PATH ?? '/'
});

export function getRequest<T = any>(url: string) {
	return axiosInstance.get<T>(url);
}

export function getRequestWithToken<T = any>(url: string) {
	const token = Cookies.get('userToken');
	return axiosInstance.get<T>(url, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token
		}
	});
}

export const postRequest = (url: string, params?: Record<string, any>) => {
	const formData: FormData = new FormData();
	Object.entries(params ?? {}).forEach(([key, value]) => {
		formData.append(key, value);
	});
	return axiosInstance.post(url, formData);
};

export const postRequestJSON = (url: string, params?: Record<string, any>) => {
	return axiosInstance.post(url, params, {
		headers: {
			'Content-Type': 'application/json'
		}
	});
};

export const postRequestJSONWithToken = (
	url: string,
	params?: Record<string, any>
) => {
	const token = Cookies.get('userToken');
	return axiosInstance.post(url, params, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token
		}
	});
};
export const patchRequestJSONWithToken = (
	url: string,
	params?: Record<string, any>
) => {
	const token = Cookies.get('userToken');
	return axiosInstance.patch(url, params, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token
		}
	});
};
export const deleteWithToken = async (url: string) => {
	const token = Cookies.get('userToken');
	return axiosInstance.delete(url, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token
		}
	});
};

/* GET METHODS */

export const getUser = async (
	email?: string,
	lastName?: string,
	firstName?: string
) => {
	const url = urlcat('/v1/user/identification', {
		email: email,
		lastName: lastName,
		firstName: firstName
	});
	return await getRequest(url);
};

export const getGames = async (eventId?: string, teamId?: number) => {
	const url = urlcat('/v1/event/games', { eventId: eventId, teamId: teamId });
	return await getRequest(url);
};
export const getSurveys = async (eventId?: string, teamId?: number) => {
	const url = urlcat('/v1/event/surveys', { eventId: eventId, teamId: teamId });
	return await getRequest(url);
};

export const getNotifications = async (eventId?: string, teamId?: number) => {
	const url = urlcat('/v1/event/notifications', {
		eventId: eventId,
		teamId: teamId
	});
	return await getRequest(url);
};

export const getTeams = async (eventId?: string) => {
	const url = urlcat('/v1/teams', { eventId: eventId });
	return await getRequest(url);
};

export const getTeam = (id: string) => getRequest(urlcat('/team/:id', { id }));

export const getSession = (id: string) =>
	getRequest(urlcat('/session/:id', { id }));

export const getRankingForSession = (sessionId: string) =>
	getRequest(urlcat('/session/:id/ranking', { id: sessionId }));

/* POST METHODS */

export const checkPadlockAnswer = (
	padlockId: string,
	answer: string[],
	sessionId?: string
) =>
	// !! Sends FormData - Replace with postRequestJSON if needed
	postRequestJSON(urlcat('/padlock/:id/check', { id: padlockId }), {
		sessionId: sessionId ?? '',
		answer
	});

//New API

export const addUserApi = async (
	userEmail: string,
	trainingsSelected: string[] = [],
	endDateAccess?: Date,
	role?: string,
	licencesNumber?: number,
	createdBy?: string
) => {
	const currentDate = new Date();
	// Add a month to the current date
	const futureDate = new Date(currentDate);
	futureDate.setMonth(currentDate.getMonth() + 1);
	return postRequestJSON('/user/add', {
		email: userEmail,
		trainings: trainingsSelected,
		endDateAccess: endDateAccess ? endDateAccess : futureDate,
		role: role ?? 'user',
		licencesNumber: licencesNumber ?? 1,
		createdBy: createdBy || ''
	});
};

export const getTokenApi = async (userEmail: string, userId: string) => {
	const url = urlcat('/user/token', {
		email: userEmail,
		userId: userId
	});
	return getRequest(url);
};

export const getUsersWithToken = async token => {
	const apiUrl = '/users'; // Replace with your API endpoint URL

	try {
		const response = await fetch(apiUrl, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		return postRequestJSON(apiUrl, {});
	} catch (error) {
		// Handle the error
		console.error('API Error:', error);
	}
};
