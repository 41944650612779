import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { getDocs, onSnapshot } from 'firebase/firestore';
//Service imports
import { activityGetWithEventsId } from '../services/activity.service';
import {
	eventGetAll,
	eventsGetFromIds,
	getEventActivities
} from '../services/event.service';
import { gamesGetWithEventsId } from '../services/game.service';
import { messageGetAll } from '../services/message.service';
import { rankingGetAll } from '../services/ranking.service';
import { rolesGetAll } from '../services/roles.service';
import { teamGet, teamGetWithPlayerId } from '../services/team.service';
import {
	getUsersAssociated,
	userGetAll,
	userGetWithEmail
} from '../services/user.service';
//Type imports
import { ITActivityData } from '../types/activity.type';
import { ITAnalyticsData } from '../types/analytics.type';
import { ITEventData } from '../types/event.type';
import { ITGameData } from '../types/game.type';
import { ITMessageData } from '../types/message.type';
import { ITPlayerData } from '../types/player.type';
import { ITRankingData } from '../types/ranking.type';
import { ITRoles } from '../types/roles.type';
import { ITTeamData } from '../types/team.type';
import { ITRole, ITUserData } from '../types/user.type';
import { dataState } from './dataSlice.interface';
import { AppThunk, RootState } from './store.interface';

// Initial State
const initialState: dataState = {
	user: undefined,
	team: undefined,
	player: undefined,
	teams: [],
	myTeams: {},
	messages: [],
	activities: [],
	allActivities: [],
	eventRanking: [],
	event: undefined,
	currentActivity: undefined,
	unityDisplay: false,
	isQuittingUnity: false,
	displayDashboard: true,
	displayMembers: false,
	displayTrainings: false,
	displayProfil: false,
	displaySettings: false,
	displayActivity: false,
	// displayStudentMode: false,
	quittingPath: '/',
	displayWebGame: false,
	eventAnalytics: [],
	users: {},
	playerEvents: [],
	events: [],
	myEvents: {},
	roles: [],
	webGame: '',
	displayDocuments: false
};

// --- API DATA ACTIONS
export const dataSlice = createSlice({
	name: 'data',
	initialState,
	reducers: {
		updateUser: (state, action: PayloadAction<ITUserData | undefined>) => {
			state.user = action.payload;
		},
		updateUsers: (
			state,
			action: PayloadAction<Record<string, unknown> | undefined>
		) => {
			state.users = action.payload;
		},
		updatePlayer: (state, action: PayloadAction<ITPlayerData | undefined>) => {
			state.player = action.payload;
		},
		updateActivities: (state, action: PayloadAction<ITActivityData[]>) => {
			state.activities = action.payload;
		},
		updateAllActivities: (state, action: PayloadAction<ITActivityData[]>) => {
			state.allActivities = action.payload;
		},
		updateCurrentActivity: (
			state,
			action: PayloadAction<ITActivityData | undefined>
		) => {
			state.currentActivity = action.payload;
		},
		updateGames: (state, action: PayloadAction<ITGameData[] | undefined>) => {
			state.games = action.payload;
		},
		updateTeams: (state, action: PayloadAction<ITTeamData[] | undefined>) => {
			state.teams = action.payload;
		},
		updateTeam: (state, action: PayloadAction<ITTeamData | undefined>) => {
			state.team = action.payload;
		},
		updateMyTeams: (state, action: PayloadAction<Record<string, ITTeamData>>) => {
			state.myTeams = action.payload;
		},
		updatePlayerEvents: (
			state,
			action: PayloadAction<ITTeamData[] | undefined>
		) => {
			state.playerEvents = action.payload;
		},
		updateMessages: (state, action: PayloadAction<ITMessageData[]>) => {
			state.messages = action.payload;
		},
		updateUnityDisplay: (state, action: PayloadAction<boolean>) => {
			state.unityDisplay = action.payload;
		},
		updateIsQuittingUnityDisplay: (state, action: PayloadAction<boolean>) => {
			state.isQuittingUnity = action.payload;
		},
		updateDisplayDashboard: (state, action: PayloadAction<boolean>) => {
			state.displayDashboard = action.payload;
		},
		updateDisplayMembers: (state, action: PayloadAction<boolean>) => {
			state.displayMembers = action.payload;
		},
		updateDisplayTrainings: (state, action: PayloadAction<boolean>) => {
			state.displayTrainings = action.payload;
		},
		updateDisplayProfil: (state, action: PayloadAction<boolean>) => {
			state.displayProfil = action.payload;
		},
		updateDisplaySettings: (state, action: PayloadAction<boolean>) => {
			state.displaySettings = action.payload;
		},
		updateDisplayActivity: (state, action: PayloadAction<boolean>) => {
			state.displayActivity = action.payload;
		},
		// updateDisplayStudentMode: (state, action: PayloadAction<boolean>) => {
		// 	state.displayStudentMode = action.payload;
		// },
		updateQuittingUnityPath: (state, action: PayloadAction<string>) => {
			state.quittingPath = action.payload;
		},
		updateDisplayWebGame: (state, action: PayloadAction<boolean>) => {
			state.displayWebGame = action.payload;
		},
		updateWebGame: (state, action: PayloadAction<string>) => {
			state.webGame = action.payload;
		},
		updateEventRanking: (state, action: PayloadAction<ITRankingData[]>) => {
			state.eventRanking = action.payload;
		},
		updateEventAnalytics: (state, action: PayloadAction<ITAnalyticsData[]>) => {
			state.eventAnalytics = action.payload;
		},
		updateEvents: (state, action: PayloadAction<ITEventData[]>) => {
			state.events = action.payload;
		},
		updateRoles: (state, action: PayloadAction<ITRoles[]>) => {
			state.roles = action.payload;
		},
		updateDisplayDocuments: (state, action: PayloadAction<boolean>) => {
			state.displayDocuments = action.payload;
		},
		// updateMyEvents: (
		// 	state,
		// 	action: PayloadAction<Record<string, ITTeamData>>
		// ) => {
		// 	state.myEvents = action.payload;
		// },
		//Compile player events and trainings
		compilePlayerEvents: state => {
			const games = current(state).games;
			const allActivities = current(state).allActivities;
			const playerEvents = current(state).playerEvents;
			const activities: ITActivityData[] = [];
			const myEvents: any = {};
			//Retrieve the player events
			playerEvents?.forEach(el => {
				myEvents[el.eventId || el.id] = el;
			});
			const gamesHash: any = {};
			//Create a dictionnary for a gmae based on its id
			games?.forEach(el => {
				gamesHash[el.id] = el;
			});
			const activitiesIdx: any = {};
			//TODO verify elements stored here
			//Update the information of the activities composing the game
			allActivities.forEach((el, index) => {
				if (!el.id) return;
				activitiesIdx[el.id] = {
					id: el.id,
					name: el.name,
					description: el.description ?? '',
					category: el.category,
					status: el.status,
					totalPoints: el.totalPoints,
					instruction: el.instruction,
					img: el.img ?? '',
					video: el.video,
					chronoType: el.chronoType ?? 'none',
					steps: el.steps?.map((e: any) => gamesHash[e]),
					autonomy: el.autonomy,
					hasClue: el.hasClue || false,
					experience: el.experience ?? {}
				};
			});
			//Create the correct activities for a player
			playerEvents?.forEach(teamActivities => {
				let activitiesTemp: ITActivityData[] = [];
				teamActivities?.activities?.forEach((activity, idx) => {
					const a = activity;
					const tmp = {
						...activitiesIdx[activity.activityId],
						...a,
						index: idx,
						isLastActivity: idx == (teamActivities?.activities?.length || 1 - 1),
						steps: [],
						totalSteps: a.steps?.length || 0
					};
					activitiesIdx[activity.activityId]?.steps?.forEach((e: any, i: any) => {
						if (e !== undefined && activity.steps && activity.steps[i]) {
							tmp.steps.push({
								...e,
								...activity.steps[i]
							});
						}
					});
					activitiesTemp = [...activitiesTemp, tmp];
					activities.push(tmp);
				});
				myEvents[teamActivities.eventId || teamActivities.id] = {
					...myEvents[teamActivities.eventId || teamActivities.id],
					activities: activitiesTemp
				};
			});
			state.allActivities = activities;
			state.myEvents = myEvents;
		}
	}
});

export const {
	updateUser,
	updateUsers,
	updateActivities,
	updateAllActivities,
	updateTeam,
	updateTeams,
	updateGames,
	updateMessages,
	updatePlayer,
	updateCurrentActivity,
	updateUnityDisplay,
	updateIsQuittingUnityDisplay,
	updateDisplayDashboard,
	updateDisplayMembers,
	updateDisplayProfil,
	updateDisplaySettings,
	updateDisplayTrainings,
	updateDisplayActivity,
	// updateDisplayStudentMode,
	updateQuittingUnityPath,
	updateDisplayWebGame,
	updateEventRanking,
	updateEventAnalytics,
	updatePlayerEvents,
	compilePlayerEvents,
	updateEvents,
	updateRoles,
	updateWebGame,
	updateDisplayDocuments,
	// updateMyEvents,
	updateMyTeams
} = dataSlice.actions;

// export const selectUser = (state: RootState) => state.data.user;
export const selectUsers = (state: RootState) => state.data.users;
export const selectPlayer = (state: RootState) => state.data.player;
export const selectActivities = (state: RootState) => {
	return state.data.activities;
};
export const selectGame = (state: RootState) => state.data.activities;
export const selectEvent = (state: RootState) => state.data.event;
export const selectTeam = (state: RootState) => state.data.team;
export const selectTeams = (state: RootState) => state.data.teams;
// export const selectMyTeams = (state: RootState) => state.data.myTeams;
export const selectMessages = (state: RootState) => state.data.messages;
export const selectCurrentActivity = (state: RootState) =>
	state.data.currentActivity;
export const selectUnityDisplayState = (state: RootState) =>
	state.data.unityDisplay;
export const selectIsQuittingUnityDisplayState = (state: RootState) =>
	state.data.isQuittingUnity;
export const selectQuittingPathState = (state: RootState) =>
	state.data.quittingPath;
export const selectDisplayDashboardState = (state: RootState) =>
	state.data.displayDashboard;
export const selectDisplayMembersState = (state: RootState) =>
	state.data.displayMembers;
export const selectDisplayTrainingsState = (state: RootState) =>
	state.data.displayTrainings;
export const selectDisplayProfilState = (state: RootState) =>
	state.data.displayProfil;
export const selectDisplaySettingsState = (state: RootState) =>
	state.data.displaySettings;
export const selectDisplayActivityState = (state: RootState) =>
	state.data.displayActivity;
// export const selectDisplayStudentMode = (state: RootState) =>
// 	state.data.displayStudentMode;
export const selectDisplayWebGame = (state: RootState) =>
	state.data.displayWebGame;
export const selectEventRanking = (state: RootState) => state.data.eventRanking;
export const selectEventAnalytics = (state: RootState) =>
	state.data.eventAnalytics;
export const selectPlayerEvents = (state: RootState) => state.data.playerEvents;
export const selectEvents = (state: RootState) => state.data.events;
export const selectGames = (state: RootState) => state.data.games;
// export const selectMyEvents = (state: RootState) => state.data.myEvents;
export const selectRoles = (state: RootState) => state.data.roles;
export const selectWebGame = (state: RootState) => state.data.webGame;
export const selectDisplayDocuments = (state: RootState) =>
	state.data.displayDocuments;

// Thunks and Async Actions
// export const initDataSnapshot = (): AppThunk => async (dispatch, getState) => {
// 	const email = getAuth().currentUser?.email;
// 	if (!email) return;
// 	const user = await getDocs(userGetWithEmail(email));
// 	if (!user.docs.length) return;
// 	const doc = user.docs[0];
// 	const userId = doc.id;
// 	const isAdmin = doc.data().role == ITRole.ADMIN;
// 	const isTrainingCenter =
// 		doc.data().role == ITRole.TRAINING_CENTER ||
// 		doc.data().role == ITRole.MULTILICENSES;

// 	// GET USER
// 	if (!isTrainingCenter) {
// 		dispatch(
// 			updateUser({
// 				id: userId,
// 				firstName: doc.data().firstName,
// 				lastName: doc?.data().lastName,
// 				email: doc?.data().email,
// 				role: doc?.data().role || 'user'
// 			})
// 		);
// 	} else {
// 		dispatch(
// 			updateUser({
// 				id: userId,
// 				firstName: doc.data().firstName,
// 				lastName: doc?.data().lastName,
// 				email: doc?.data().email,
// 				role: doc?.data().role || 'trainingcenter',
// 				licencesNumber: doc?.data().licencesNumber || 1,
// 				licencesUsed: doc?.data().licencesUsed || 1
// 			})
// 		);
// 	}

// 	// GET TEAM
// 	onSnapshot(teamGet(userId), async (d: any) => {
// 		if (!d.docs || !d.docs.length) return;
// 		const team = d.docs[0];
// 		const data = team.data();
// 		await dispatch(
// 			updateTeam({
// 				id: team.id,
// 				avatar: team.data().avatar,
// 				name: team?.data().name,
// 				eventId: team?.data().eventId,
// 				points: team?.data().points,
// 				playersCount: team?.data().playersCount || team?.data().players.length,
// 				ranking: team?.data().ranking || 0,
// 				activities: team?.data().activities,
// 				isTeamNameSet: team?.data().isTeamNameSet,
// 				teamSlogan: team?.data().teamSlogan,
// 				teamValues: team?.data().teamValues,
// 				players: team?.data().players
// 			})
// 		);
// 	});

// 	// Get player teams
// 	onSnapshot(teamGetWithPlayerId(userId), async (d: any) => {
// 		if (!d.docs.length) return;
// 		const team = d.docs[0];
// 		const playerEvents: ITTeamData[] = [];
// 		//We get all user event
// 		d.docs.forEach(element => {
// 			const data = element.data();
// 			const newEvent = {
// 				id: element.id,
// 				avatar: data.avatar,
// 				name: data.name,
// 				eventId: data.eventId,
// 				points: data.points,
// 				ranking: data.ranking || 0,
// 				activities: data.activities,
// 				playersCount: data.playersCount || data.players.length,
// 				isTeamNameSet: data.isTeamNameSet,
// 				teamSlogan: data.teamSlogan,
// 				teamValues: data.teamValues,
// 				players: data.players
// 			};
// 			playerEvents.push(newEvent);
// 		});
// 		await dispatch(
// 			//We use currently this player
// 			updatePlayerEvents(playerEvents)
// 		);

// 		// Get games with eventIds from playerEvents
// 		const eventIds = playerEvents
// 			.map(event => event.eventId)
// 			.filter(eventId => eventId !== undefined) as string[];

// 		if (isAdmin) {
// 			//GET All event
// 			onSnapshot(eventGetAll(), (d: any) => {
// 				dispatch(
// 					updateEvents(
// 						d.docs.map((doc: any) => ({
// 							id: doc.id,
// 							configurations: doc.data().configurations,
// 							description: doc.data().description,
// 							branding: doc.data().branding,
// 							name: doc.data().name,
// 							startAt: doc.data().startAt
// 						}))
// 					)
// 				);
// 			});
// 		}

// 		//GET All event
// 		onSnapshot(eventsGetFromIds(eventIds), (snapshot: any) => {
// 			const events = snapshot.docs.map(doc => {
// 				const data = doc.data();
// 				return {
// 					id: doc.id,
// 					configurations: data.configurations,
// 					description: data.description,
// 					branding: data.branding,
// 					name: data.name,
// 					startAt: data.startAt
// 				};
// 			});
// 			dispatch(updateEvents(events));
// 		});
// 		// }

// 		onSnapshot(gamesGetWithEventsId(eventIds), snapshot => {
// 			const games = snapshot.docs.map(doc => {
// 				const data = doc.data();
// 				return {
// 					id: doc.id,
// 					chronoType: data.chronoType ?? '',
// 					durationInSeconds: data.durationInSeconds,
// 					eventId: data.eventId,
// 					figuresCount: data.figuresCount,
// 					gameType: data.gameType,
// 					gridSizeX: data.gridSizeX || 0,
// 					gridSizeY: data.gridSizeY || 0,
// 					instruction: data.instruction,
// 					maxRetries: data.maxRetries,
// 					name: data.name,
// 					img: data.img || '',
// 					solution: data.solution,
// 					visuals: data.visuals,
// 					modifiedAt: data.modifiedAt,
// 					createdAt: data.createdAt,
// 					clue: data.clue,
// 					hasClue: data.hasClue || false,
// 					bonusCoeff: data.bonusCoeff,
// 					bonusPoints: data.bonusPoints,
// 					hasPenaltyPoints: data.hasPenaltyPoints ?? false,
// 					penaltyPoints: data.penaltyPoints,
// 					hasBonusCoeff: data.hasBonusCoeff,
// 					stepsNumber: data.stepsNumber ?? 0,
// 					stepIndex: data.stepIndex ?? 0
// 				};
// 			});
// 			dispatch(updateGames(games));
// 		});

// 		onSnapshot(activityGetWithEventsId(eventIds), async (d: any) => {
// 			if (!d.docs.length) return;
// 			const a: Partial<ITActivityData>[] = [];

// 			d.docs.forEach((doc: any) => {
// 				const l = doc.data();
// 				a.push({
// 					id: doc.id,
// 					name: doc.data().name,
// 					description: doc?.data().description || '',
// 					category: doc?.data().category,
// 					status: doc?.data().status,
// 					totalPoints: doc?.data().totalPoints,
// 					img: doc?.data().img || '',
// 					video: doc?.data().video,
// 					chronoType: doc?.data().chronoType || '',
// 					steps: doc?.data().steps,
// 					instruction: doc?.data().instruction,
// 					autonomy: doc?.data().autonomy,
// 					hasClue: doc?.data().hasClue || false,
// 					experience: doc?.data().experience || {}
// 				});
// 			});
// 			await dispatch(updateAllActivities(a));
// 			//We need to compile
// 			await dispatch(compilePlayerEvents());
// 		});
// 	});

// 	onSnapshot(rankingGetAll(), (q: any) => {
// 		dispatch(
// 			updateEventRanking(
// 				q.docs.map((doc: any) => ({
// 					id: doc.id,
// 					teamID: doc.data().teamID,
// 					teamName: doc.data().teamName,
// 					eventId: doc.data().eventId,
// 					duration: doc.data().duration,
// 					ts: doc.data().ts
// 				}))
// 			)
// 		);
// 		//We update the data
// 	});

// 	// 	//We update the data
// 	// });
// 	if (isAdmin) {
// 		const userQuery = userGetAll(); // Retrieve the Firestore query
// 		onSnapshot(userQuery, querySnapshot => {
// 			try {
// 				const { user } = getState().data;

// 				if (user?.role === 'admin') {
// 					const usersData = Object.fromEntries(
// 						querySnapshot.docs.map(doc => {
// 							const data = doc.data();
// 							return [
// 								doc.id,
// 								{
// 									firstName: data?.firstName,
// 									lastName: data?.lastName,
// 									email: data?.email,
// 									role: data?.role || 'user'
// 								}
// 							];
// 						})
// 					);

// 					dispatch(updateUsers(usersData));
// 				}
// 			} catch (error) {
// 				console.error('Error retrieving user data:', error);
// 				// Handle the error appropriately
// 			}
// 		});

// 		const rolesQuery = rolesGetAll(); // Retrieve the Firestore query
// 		onSnapshot(rolesQuery, querySnapshot => {
// 			try {
// 				const { user } = getState().data;

// 				if (user?.role === 'admin') {
// 					const rolesData = querySnapshot.docs.map(doc => {
// 						const data = doc.data();
// 						return {
// 							id: doc.id,
// 							name: data.name,
// 							permissions: data.permissions ?? []
// 						};
// 					});
// 					dispatch(updateRoles(rolesData));
// 				}
// 			} catch (error) {
// 				console.error('Error retrieving user data:', error);
// 				// Handle the error appropriately
// 			}
// 		});
// 	}

// 	if (isTrainingCenter) {
// 		const userQuery2 = await getUsersAssociated(userId); // Retrieve the Firestore query

// 		onSnapshot(userQuery2, querySnapshot => {
// 			try {
// 				const { user } = getState().data;

// 				const usersData = Object.fromEntries(
// 					querySnapshot.docs.map(doc => {
// 						const data = doc.data();
// 						return [
// 							doc.id,
// 							{
// 								firstName: data?.firstName,
// 								lastName: data?.lastName,
// 								email: data?.email,
// 								role: data?.role || 'user'
// 							}
// 						];
// 					})
// 				);

// 				dispatch(updateUsers(usersData));
// 			} catch (error) {
// 				console.error('Error retrieving user data:', error);
// 				// Handle the error appropriately
// 			}
// 		});
// 	}

// 	onSnapshot(messageGetAll(), (q: any) => {
// 		dispatch(
// 			updateMessages(
// 				q.docs.map((doc: any) => ({
// 					id: doc.id,
// 					author: doc.data().author,
// 					content: doc.data().content,
// 					time: doc.data().createdAt
// 				}))
// 			)
// 		);
// 	});
// };

// Thunks and Async Actions
export const retrieveUserActivitiesEvent = (eventId): AppThunk => {
	return async (dispatch, getState) => {
		// GET USER
		const activities = await getEventActivities(eventId);
		const playerActivities = getState().data.myTeams?.[eventId].activities;
		const activitiesMerged: any[] = [];
		activities.forEach((element, index) => {
			const indexInArray1 = playerActivities.findIndex(
				elem => elem.activityId === element.id
			);
			if (indexInArray1 > -1) {
				activitiesMerged.push(
					mergeObjectsWithPriority(element, playerActivities[indexInArray1])
				);
			}
		});
		await dispatch(updateActivities(activitiesMerged));
	};
};

function mergeObjectsWithPriority(obj1, obj2) {
	const mergedObject = { ...obj2 }; // Create a copy of obj2

	for (const key of Object.keys(obj1)) {
		// Check if the key from obj1 exists in obj2
		if (!(key in obj2)) {
			// If the key does not exist in obj2, add it to the mergedObject
			mergedObject[key] = obj1[key];
		}
	}

	return mergedObject;
}
export default dataSlice.reducer;
