import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
	Button,
	Dialog,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import {
	Adb,
	AdbDaemonDevice,
	AdbDaemonTransport,
	AdbPacketData,
	AdbPacketInit
} from '@yume-chan/adb';
import AdbWebCredentialStore from '@yume-chan/adb-credential-web';
import {
	AdbDaemonWebUsbDeviceManager,
	AdbDaemonWebUsbDeviceWatcher
} from '@yume-chan/adb-daemon-webusb';
import {
	Consumable,
	InspectStream,
	ReadableStream,
	WritableStream,
	pipeFrom
} from '@yume-chan/stream-extra';
import { observer } from 'mobx-react-lite';
import { GLOBAL_STATE } from '../../state';
import StreamIcon from '@mui/icons-material/Stream';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PowerOffIcon from '@mui/icons-material/PowerOff';

const CredentialStore = new AdbWebCredentialStore('ptitbout');

function ConnectCore(): JSX.Element | null {
	const [selected, setSelected] = useState<AdbDaemonDevice | undefined>();
	const [connecting, setConnecting] = useState(false);
	const [usbSupported, setUsbSupported] = useState(true);
	const [usbDeviceList, setUsbDeviceList] = useState<AdbDaemonDevice[]>([]);
	const updateUsbDeviceList = useCallback(async () => {
		const devices: AdbDaemonDevice[] =
			await AdbDaemonWebUsbDeviceManager.BROWSER!.getDevices();
		setUsbDeviceList(devices);
		return devices;
	}, []);
	console.log('CredentialStore init', CredentialStore);
	useEffect(() => {
		// Only run on the client
		const supported = !!AdbDaemonWebUsbDeviceManager.BROWSER;
		setUsbSupported(supported);

		if (!supported) {
			GLOBAL_STATE.showErrorDialog(
				'Your browser does not support WebUSB standard, which is required for this site to work.\n\nLatest version of Google Chrome, Microsoft Edge, or other Chromium-based browsers are required.'
			);
			return;
		}

		updateUsbDeviceList();

		const watcher = new AdbDaemonWebUsbDeviceWatcher(async (serial?: string) => {
			const list = await updateUsbDeviceList();

			if (serial) {
				setSelected(list.find(device => device.serial === serial));
				return;
			}
		}, globalThis.navigator.usb);

		return () => watcher.dispose();
	}, []);

	const handleSelectedChange = (value: unknown) => {
		setSelected(value as AdbDaemonDevice);
	};

	const addUsbDevice = useCallback(async () => {
		const device = await AdbDaemonWebUsbDeviceManager.BROWSER!.requestDevice();
		setSelected(device);
		await updateUsbDeviceList();
	}, [updateUsbDeviceList]);

	const connect = useCallback(async () => {
		if (!selected) {
			return;
		}

		setConnecting(true);

		let readable: ReadableStream<AdbPacketData>;
		let writable: WritableStream<Consumable<AdbPacketInit>>;
		try {
			console.log('trying to connect', selected);
			const streams = await selected.connect();
			console.log('connection successful', streams);

			// Use `InspectStream`s to intercept and log packets
			readable = streams.readable.pipeThrough(
				new InspectStream(packet => {
					GLOBAL_STATE.appendLog('in', packet);
				})
			);
			console.log('readable successful', readable);

			writable = pipeFrom(
				streams.writable,
				new InspectStream((packet: Consumable<AdbPacketInit>) => {
					GLOBAL_STATE.appendLog('out', packet.value);
				})
			);
			console.log('writable successful', writable);
			console.log('connected');
			console.log('GLOBAL_STATE.adb', GLOBAL_STATE.adb);
			console.log('GLOBAL_STATE', GLOBAL_STATE);
		} catch (e: any) {
			console.error('Error on connection');
			GLOBAL_STATE.showErrorDialog(e);
			setConnecting(false);
			return;
		}

		async function dispose() {
			// Adb won't close the streams,
			// so manually close them.
			try {
				readable.cancel();
			} catch {
				console.log('cancel writable');
			}
			try {
				await writable.close();
			} catch {
				console.log('cancel writable');
			}
			GLOBAL_STATE.setDevice(undefined, undefined);
		}

		try {
			console.log('creating adb');
			console.log(selected.serial);
			console.log('store', CredentialStore);

			const device = new Adb(
				await AdbDaemonTransport.authenticate({
					serial: selected.serial,
					connection: { readable, writable },
					credentialStore: CredentialStore
				})
			);
			console.log('adb created', device);

			device.disconnected.then(
				async () => {
					await dispose();
				},
				async e => {
					GLOBAL_STATE.showErrorDialog(e);
					await dispose();
				}
			);
			console.log('adb disconnection set', device);

			GLOBAL_STATE.setDevice(selected, device);
			console.log('GLOBAL_STATE', GLOBAL_STATE);
		} catch (e: any) {
			console.log('adb error set', e);
			GLOBAL_STATE.showErrorDialog(e);
			await dispose();
		} finally {
			setConnecting(false);
		}
	}, [selected]);

	const disconnect = useCallback(async () => {
		try {
			await GLOBAL_STATE.adb!.close();
		} catch (e: any) {
			GLOBAL_STATE.showErrorDialog(e);
		}
	}, []);

	const deviceList = useMemo(
		() => ([] as AdbDaemonDevice[]).concat(usbDeviceList),
		[usbDeviceList]
	);

	const deviceOptions = useMemo(() => {
		return deviceList.map(device => ({
			value: device.serial,
			label: `${device.serial} ${device.name ? `(${device.name})` : ''}`
		}));
	}, [deviceList]);

	useEffect(() => {
		setSelected(old => {
			if (old) {
				const current = deviceList.find(device => device.serial === old.serial);
				if (current) {
					return current;
				}
			}

			return deviceList.length ? deviceList[0] : undefined;
		});
	}, [deviceList]);

	const addMenuProps = useMemo(() => {
		const items: Array<{
			key: string;
			text: string;
			onClick: () => Promise<void>;
		}> = [];

		if (usbSupported) {
			items.push({
				key: 'usb',
				text: 'USB',
				onClick: addUsbDevice as () => Promise<void>
			});
		}

		return items;
	}, [usbSupported, addUsbDevice]);

	return (
		<Stack spacing={2}>
			<FormControl fullWidth>
				<InputLabel>Available devices</InputLabel>
				<Select
					value={selected?.serial || ''}
					onChange={(event: SelectChangeEvent<string>) => {
						const newValue = event.target.value;
						handleSelectedChange({ value: newValue });
					}}
				>
					{deviceOptions.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			{!GLOBAL_STATE.adb ? (
				<Stack direction='row' spacing={2}>
					<Button
						variant='contained'
						startIcon={<StreamIcon />}
						disabled={!selected}
						onClick={connect}
					>
						Connect
					</Button>
					<Button
						variant='contained'
						startIcon={<AddCircleOutlineIcon />}
						onClick={addUsbDevice}
						disabled={!usbSupported}
					>
						Add
					</Button>
				</Stack>
			) : (
				<Button
					variant='contained'
					startIcon={<PowerOffIcon />}
					onClick={disconnect}
				>
					Disconnect
				</Button>
			)}

			<Dialog open={connecting} onClose={() => setConnecting(false)}>
				<Typography variant='h6'>Connecting...</Typography>
				{/* <ProgressIndicator /> */}
			</Dialog>
		</Stack>
	);
}

export const Connect = observer(ConnectCore);
