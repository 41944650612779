import {
	browserSessionPersistence,
	getAuth,
	setPersistence,
	signInWithEmailAndPassword,
	signOut,
	sendSignInLinkToEmail,
	signInWithEmailLink,
	signInWithCustomToken,
	isSignInWithEmailLink,
	sendPasswordResetEmail,
	confirmPasswordReset
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { getDocs } from 'firebase/firestore';
import { userGetWithEmail, addUser } from './user.service';
import { addTeamConnectionAnalytics } from './analytics.service';
import Cookies from 'js-cookie';

export const DISPATCH_ERROR = 'DISPATCH_ERROR';
export function dispatchError(error) {
	return {
		type: DISPATCH_ERROR,
		message: error
	};
}

const userDoexNotExistError =
	'Veuillez vérifier que vous avez une licence pour utiliser Buga Learn';
const loginError = 'Veuillez vérifier vos identifiants de connexion';

export const AuthSignIn = async (email: string, password: string) => {
	try {
		// const auth = getAuth();
		// await setPersistence(auth, browserSessionPersistence);
		// const result = await signInWithEmailAndPassword(auth, email, password);
		return { connected: true };
	} catch (error) {
		const firebaseError = error as FirebaseError;
		switch (firebaseError.code) {
			case 'auth/invalid-email':
				console.log('Invalid email address');
				break;
			case 'auth/user-disabled':
				console.log('This account has been disabled');
				break;
			case 'auth/user-not-found':
				console.log('User not found');
				break;
			case 'auth/wrong-password':
				console.log('Incorrect password');
				break;
			default:
				console.log('An unknown error occurred');
		}
		// Some error occurred.
		console.log('Error email connection:', error);
		return { connected: false, errorMessage: loginError };
	}
};

export const AuthSignInWithToken = async (token: string) => {
	//TODO check if there is multiple same account
	try {
		// const auth = getAuth();
		// const result = await signInWithCustomToken(auth, token);
		// await setPersistence(auth, browserSessionPersistence);
		return { connected: true };
	} catch (error) {
		const firebaseError = error as FirebaseError;
		switch (firebaseError.code) {
			case 'auth/invalid-email':
				console.log('Invalid email address');
				break;
			case 'auth/user-disabled':
				console.log('This account has been disabled');
				break;
			case 'auth/user-not-found':
				console.log('User not found');
				break;
			case 'auth/wrong-password':
				console.log('Incorrect password');
				break;
			default:
				console.log('An unknown error occurred');
		}
		// Some error occurred.
		console.log('Error email connection:', error);
		return { connected: false, errorMessage: loginError };
	}
};

export const SendSignInLinkToEmail = async (email: string): Promise<void> => {
	const actionCodeSettings = {
		// URL you want to redirect back to. The domain (www.example.com) for this
		// URL must be whitelisted in the Firebase Console.
		url: process.env.REACT_APP_BASE_URL + '/login',
		// This must be true.
		handleCodeInApp: true
	};
	// const auth = getAuth();
	// window.localStorage.setItem('emailForSignIn', email);
	// const user = await getDocs(userGetWithEmail(email));
	// if (!user.docs.length) {
	// 	//no user
	// 	// We need to create a user
	// 	const newUser = await addUser(email);
	// }
	// //Once the user is created, we log in
	// try {
	// 	await sendSignInLinkToEmail(auth, email, actionCodeSettings);
	// 	window.localStorage.setItem('emailForSignIn', email);
	// } catch (error) {
	// 	console.error('Error sending email:', error);
	// }
};

// Handle sign-in when the user clicks the link
export const HandleSignInWithEmailLink = async () => {
	const email = window.localStorage.getItem('emailForSignIn');
	if (!email) {
		console.error('No email found in storage for sign-in link.');
		return;
	}
	// const auth = getAuth();
	// if (isSignInWithEmailLink(auth, window.location.href)) {
	// 	// This can be used to continue the user's intended action before triggering
	// 	// the sign-in operation.
	// 	// Get the email if available. This should be available if the user completes
	// 	// the flow on the same device where they started it.
	// 	let emailStored = window.localStorage.getItem('emailForSignIn');
	// 	if (!emailStored) {
	// 		// User opened the link on a different device. To prevent session fixation
	// 		// attacks, ask the user to provide the associated email again. For example:
	// 		//TODO Update this
	// 		emailStored = window.prompt('Please provide your email for confirmation');
	// 	}
	// 	try {
	// 		await signInWithEmailLink(auth, email, window.location.href).then(result => {
	// 			// console.log('connexion success ', result);
	// 			addTeamConnectionAnalytics(
	// 				process.env.REACT_APP_EVENT_ID || '',
	// 				result.user.uid || ''
	// 			);
	// 		});
	// 		window.localStorage.removeItem('emailForSignIn');
	// 	} catch (error) {
	// 		console.error('Error signing in with email link:', error);
	// 	}
	// }
};

export const AuthSignOut = () => {
	Cookies.remove('userToken');
};
// export const AuthGetUser = () => {
// 	return getAuth().currentUser;
// };

// Handle sign-in when the user clicks the link
export const HandleResetPassword = async email => {
	try {
		// const auth = getAuth();
		// const result = await sendPasswordResetEmail(auth, email);
	} catch (error) {
		// Handle any errors that occurred during the password reset request
		console.error('Error sending password reset email:', error);
	}
};

export const ResetPassword = async (oobCode, password) => {
	try {
		// const auth = getAuth();
		// const result = await confirmPasswordReset(auth, oobCode, password);

		// Password reset email sent successfully
		return { connected: true };
	} catch (error) {
		// Handle any errors that occurred during the password reset request
		console.error('Error sending password reset email:', error);
	}
};
