import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store.interface';
import {
	LoadingState,
	UIErrors,
	UIState,
	UISuccesses
} from './UISlice.interface';

const initialState: UIState = {
	customTheme: {},
	currentTab: 'activities',
	sidebarVisibility: false
};

export const UISlice = createSlice({
	name: 'UIData',
	initialState,
	reducers: {
		updateIsLoading: (state, action: PayloadAction<LoadingState>) => {
			state.isLoading = { ...state.isLoading, ...action.payload };
		},
		updateSuccesses: (state, action: PayloadAction<UISuccesses>) => {
			state.successes = { ...state.successes, ...action.payload };
		},
		updateErrors: (state, action: PayloadAction<UIErrors>) => {
			state.errors = { ...state.errors, ...action.payload };
		},
		updateCurrentEventId: (state, action: PayloadAction<string>) => {
			state.currentEventId = action.payload;
		},
		updateCurrentTab: (state, action: PayloadAction<string>) => {
			state.currentTab = action.payload;
		},
		updateSidebarVisibility: (state, action: PayloadAction<boolean>) => {
			state.sidebarVisibility = action.payload;
		},
		resetErrors: (state, action: PayloadAction<keyof UIErrors>) => {
			state.errors = { ...state.errors, [action.payload]: undefined };
		},
		cleanUIData: () => {
			return {};
		}
	}
});

// --- ACTIONS
export const {
	cleanUIData,
	updateErrors,
	resetErrors,
	updateSuccesses,
	updateIsLoading,
	updateCurrentEventId,
	updateCurrentTab,
	updateSidebarVisibility
} = UISlice.actions;

// --- SELECTORS

export const selectUIErrors = (state: RootState) => state.UIData.errors;
export const selectUISuccesses = (state: RootState) => state.UIData.successes;
export const selectIsLoading = (state: RootState) => state.UIData.isLoading;
export const selectCurrentEventId = (state: RootState) =>
	state.UIData.currentEventId;
export const selectCurrentTab = (state: RootState) => state.UIData.currentTab;
export const selectSidebarVisibility = (state: RootState) =>
	state.UIData.sidebarVisibility;

export default UISlice.reducer;
