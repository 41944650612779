import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { Home as HomeIcon } from '../../Icons/home';
import { User as UserIcon } from '../../Icons/user';
import { NavigationItem } from './NavigationItem';
import { updateCurrentActivity } from '../../../app/dataSlice';
import logo from '../../../Assets/logo.png';
import {
	updateDisplayActivity,
	updateUnityDisplay,
	selectUnityDisplayState
} from '../../../app/dataSlice';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SchoolIcon from '@mui/icons-material/School';
import LogoutIcon from '@mui/icons-material/Logout';

import { ITRole } from '../../../types/user.type';
import { CTA } from '../../Atoms/MyButton/CTA';
import { UserContext } from '../../../app/UserContextProvider';

const itemsUser = [
	{
		href: '/',
		icon: <SchoolIcon />,
		title: 'Accueil'
	},
	{
		href: '/headset',
		icon: <UserIcon fontSize='small' />,
		title: 'Mes lunettes'
	},
	{
		href: '/tablet',
		icon: <UserIcon fontSize='small' />,
		title: 'Ma tablette'
	},
	{
		href: '/training',
		icon: <UserIcon fontSize='small' />,
		title: 'Tutoriels'
	},
	{
		href: '/data',
		icon: <UserIcon fontSize='small' />,
		title: 'Mes données'
	},
	{
		href: '/settings',
		icon: <UserIcon fontSize='small' />,
		title: 'Mon profil'
	}
];
const itemsAdmin = [
	{
		href: '/',
		icon: <SchoolIcon />,
		title: 'Accueil'
	},
	{
		href: '/admin',
		icon: <AdminPanelSettingsIcon />,
		title: 'Licences'
	},
	{
		href: '/#',
		icon: <UserIcon fontSize='small' />,
		title: 'Mes lunettes'
	},
	{
		href: '/#',
		icon: <UserIcon fontSize='small' />,
		title: 'Ma tablette'
	},
	{
		href: '/#',
		icon: <UserIcon fontSize='small' />,
		title: 'Tutoriels'
	},
	{
		href: '/#',
		icon: <UserIcon fontSize='small' />,
		title: 'Mes données'
	},
	{
		href: '/settings',
		icon: <UserIcon fontSize='small' />,
		title: 'Mon profil'
	}
];

interface NavigationBarProps {}

export const NavigationBar = () => {
	const dispatch = useDispatch();
	const context = useContext(UserContext);
	const user = context?.user;
	const isAdmin = context?.isAdmin;
	const lgUp = useMediaQuery((theme: Theme) => theme?.breakpoints.up('lg'), {
		defaultMatches: true,
		noSsr: false
	});

	async function handleLogout() {
		dispatch(updateCurrentActivity(undefined));
		dispatch(updateDisplayActivity(false));
		context?.logout();
	}

	const handleOpenGuide = () => {
		window.open(process.env.REACT_APP_GUIDE, '_blank');
	};

	const navItems = isAdmin ? itemsAdmin : itemsUser;
	return (
		<div className='flex h-full'>
			<div className='flex flex-col h-full w-full overflow-hidden items-center'>
				<img
					src={logo}
					alt='logo-ptitbout'
					className={`justify-center items-center m-8 ${'w-20'}`}
				/>

				<div className='flex flex-col flex-grow items-center'>
					{navItems.map(item => (
						<NavigationItem
							key={`nav-${item.title}`}
							icon={item.icon}
							href={item.href}
							title={item.title}
						/>
					))}
				</div>
				<div
					className={`group flex flex-row justify-items-center
                        h-32 py-0.5 m-auto rounded-lg cursor-pointer
                        active:border-white drop-shadow w-full
                        `}
				>
					<div
						onClick={handleOpenGuide}
						className={`hover:bg-primary hover:text-white m-auto h-[5vh] flex justify-center items-center rounded-md`}
						style={{ width: '80%' }}
					>
						<span className={`text-xs font-bold ease-in-out`}>
							{"Guide d'utilisation"}
						</span>
					</div>
				</div>
				<CTA
					onClick={e => {
						handleLogout();
					}}
					icon={<LogoutIcon />}
					iconPosition='start'
					variant='outlined-dark'
					title='Déconnexion'
					classes='mb-4 max-w-max mx-auto'
				/>
			</div>
		</div>
	);
};
