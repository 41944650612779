import { useDispatch, useSelector } from 'react-redux';
import {
	updateIsQuittingUnityDisplay,
	updateQuittingUnityPath,
	selectUnityDisplayState,
	selectIsQuittingUnityDisplayState,
	selectQuittingPathState
} from '../../../app/dataSlice';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

interface NavigationItemProps {
	href: string;
	icon: JSX.Element;
	title: string;
}

export const NavigationItem = (props: NavigationItemProps) => {
	const { href, icon, title } = props;
	const navigate = useNavigate();
	const displayUnityState = useSelector(selectUnityDisplayState);
	const isQuittingUnityState = useSelector(selectIsQuittingUnityDisplayState);
	const linkToNavigate = useSelector(selectQuittingPathState);

	const dispatch = useDispatch();
	const location = useLocation();
	const currentLocation =
		href === '/'
			? location.pathname === '/' || location.pathname.startsWith('/event/')
			: location.pathname.startsWith(href);

	const handleNavigation = () => {
		if (displayUnityState) {
			dispatch(updateQuittingUnityPath(href));
			if (!isQuittingUnityState) {
				dispatch(updateIsQuittingUnityDisplay(true));
			} else {
				navigate(linkToNavigate);
			}
		} else {
			navigate(href);
		}
	};
	return (
		<div
			onClick={handleNavigation}
			className={`group flex flex-row justify-center items-center p-2 mx-2 px-4 w-11/12 h-12
				hover:bg-primary-selected hover:text-white cursor-pointer rounded
				active:border-2 active:border-white ${
					currentLocation ? 'text-primary-selected' : 'text-primary'
				}`}
		>
			<span className='font-bold my-1'>{title}</span>
		</div>
	);
};
