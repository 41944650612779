import { Alert, TextField, Typography, FormGroup } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import logo from '../../Assets/logo.png';
import { HandleResetPassword } from '../../services/auth.service';
import { MyButton } from '../../Components/Atoms/MyButton/MyButton';
import { PageCentered } from '../Page/PageCentered';
import background_login from '../../Assets/background.png';
import Link from '@mui/material/Link';

interface ForgotPasswordPageProps {
	prop1?: string;
}

// const password = 'buga2022'; // TODO: find a better a solution
const defautlError = 'Veuillez accepter les conditions';
const textDescription =
	'Entrez votre email pour recevoir un lien pour mettre à jour votre mot de passe';
const noInputError = 'Veuillez entrer votre email';
const emailError = 'Veuillez entrer un email valide';
const emailSent = 'un email vous a été envoyé';

export const ForgotPasswordPage = ({
	prop1,
	...props
}: ForgotPasswordPageProps) => {
	const [email, setEmail] = useState<string>('');
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [errorMsg, setErrorMsg] = useState<string>(defautlError);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (email.length < 1) {
			setError(true);
			setErrorMsg(noInputError);
			return;
		}
		if (!isValidEmail(email)) {
			setError(true);
			setErrorMsg(emailError);
			return;
		}
		HandleResetPassword(email);
	};

	function displayError(errorMessage) {
		setError(true);
		setErrorMsg(errorMessage);
	}

	function DisplayTemporaryErrorMessage(errorMessage, timing = 10000) {
		displayError(errorMessage);
		setTimeout(() => {
			setError(false);
		}, timing); // remove error message after timing milliseconds
	}

	function isValidEmail(email) {
		// regular expression for email validation
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	return (
		<PageCentered
			bgColor='primary.main'
			bgImg={background_login}
			centeredContent={true}
		>
			<Box
				sx={{
					margin: 'auto',
					boxSizing: 'border-box',
					display: 'flex',
					alignItems: 'center',
					verticalAlign: 'middle',
					height: '100%',
					p: 2,
					pt: 1,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					overflow: 'hidden'
				}}
			>
				<Box
					sx={{
						width: '100%',
						flexDirection: 'column',
						display: 'flex',
						alignItems: 'center',
						overflow: 'hidden'
					}}
				>
					<Box sx={{ typography: 'h1', marginBottom: '2rem', marginTop: '1rem' }}>
						Connexion
					</Box>
					<Box sx={{ maxWidth: '80%', textAlign: 'center' }}>
						<Typography>{textDescription}</Typography>
						<TextField
							autoFocus
							fullWidth
							margin='dense'
							id='name'
							label='Adresse email'
							type='email'
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
					</Box>
					<Box>
						<Typography>
							<Link href='/login'>Se connecter avec son mot de passe</Link>
						</Typography>
					</Box>
					<Box sx={{ width: '80%', maxWidth: '90%' }}>
						<form onSubmit={handleSubmit}>
							<FormGroup
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-start'
								}}
							>
								{error && <Typography color='error'>{errorMsg}</Typography>}
								{success && (
									<Typography fontSize='0.7rem' color='green'>
										{emailSent}
									</Typography>
								)}
							</FormGroup>
							<Box sx={{ textAlign: 'center' }}>
								<MyButton
									variant='contained'
									type='submit'
									sx={{
										width: '80%',
										borderRadius: 10,
										height: '3rem',
										marginTop: {
											xs: '1rem',
											sm: '1.3rem',
											md: '1.7rem'
										},
										marginBottom: '0.5rem'
									}}
								>
									Réinitialiser son mot de passe
								</MyButton>
							</Box>
						</form>
					</Box>
				</Box>
			</Box>
		</PageCentered>
	);
};
