interface LabelProps {
	label: string;
	labelValue: string;
	isSmall?: boolean;
	isRound?: boolean;
	labelColor?: 'dark' | 'light';
}
export const CardLabel = ({
	label,
	labelValue,
	isSmall = false,
	isRound = false,
	labelColor
}: LabelProps) => {
	const size = isSmall ? 'text-xxs' : 'text-xs';
	const rounded = isRound ? 'rounded-lg' : 'rounded-md';
	return (
		<div
			className={`flex ${size} ${rounded} bg-gray-semitransparent px-2 text-center justify-center items-center h-8
			${labelColor === 'dark' ? 'text-primary' : 'text-white'}
			${isSmall ? 'w-max px-8' : 'w-11/12'}`}
		>
			{label ? `${label} :` : ''}
			<span className={`${size} font-semibold ml-1`}>{labelValue}</span>
		</div>
	);
};
