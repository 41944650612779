import { Page } from '../Page/Page';

import { AdminDashboard } from './AdminDashboard/AdminDashboard';
import { UserDashboard } from './UserDashboard/UserDashboard';

interface DashboardPageProps {
	isAdminDashboard: boolean;
}
export const DashboardPage = ({ isAdminDashboard }: DashboardPageProps) => {
	return (
		<Page>{isAdminDashboard ? <AdminDashboard /> : <UserDashboard />}</Page>
	);
};
