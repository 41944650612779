import { configureStore } from '@reduxjs/toolkit';
import dataReducer from './dataSlice';
import UIreducer from './UISlice';

const state = JSON.parse(localStorage.getItem('reduxState') ?? '{}');

export const store = configureStore({
	reducer: {
		UIData: UIreducer,
		data: dataReducer
	},
	preloadedState: state,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false
		})
});
store.subscribe(() => {
	localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});
export default store;
