import type { FC } from 'react';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Card,
	CardContent,
	Stack,
	TextField,
	Typography,
	Unstable_Grid2 as Grid,
	IconButton,
	InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FileCopy } from '@mui/icons-material';
import { getUserToken, getUsers } from '../../../services/user.service';
import { bugaColor } from '../../../theme';
import { CTA } from '../../../Components/Atoms/MyButton/CTA';

import { useContext } from 'react';
import { UserContext } from '../../../app/UserContextProvider';

interface LoginEvent {
	id: string;
	createdAt: number;
	ip: string;
	type: string;
	userAgent: string;
}

interface AccountSecuritySettingsProps {
	loginEvents: LoginEvent[];
}

export const AccountSecuritySettings: FC<
	AccountSecuritySettingsProps
> = props => {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [showToken, setShowToken] = useState<boolean>(false);
	const [apiToken, setApiToken] = useState('');
	const [isCopied, setIsCopied] = useState(false);
	const context = useContext(UserContext);
	const isAdmin = context?.isAdmin;
	const user = context?.user;

	const handleEdit = useCallback((): void => {
		setIsEditing(prevState => !prevState);
	}, []);

	const handleToggleSetShowToken = () => {
		setShowToken(!showToken);
	};

	async function handleRequestToken() {
		// Make your API call to request a new token
		const token = await getUserToken(user?.email, user?.id);
		// Replace the following code with your actual API call logic
		setTimeout(() => {
			//TODO WHY THIS ?
			const newToken = token.token; // Replace with the new token received from the API call
			setApiToken(newToken);
		}, 1000);
	}

	const copyTextToClipboard = async text => {
		if ('clipboard' in navigator) {
			return await navigator.clipboard.writeText(text);
		} else {
			return document.execCommand('copy', true, text);
		}
	};

	// onClick handler function for the copy button
	const handleCopyClick = () => {
		// Asynchronously call copyTextToClipboard
		copyTextToClipboard(apiToken)
			.then(() => {
				// If successful, update the isCopied state value
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 1500);
			})
			.catch(err => {
				console.log(err);
			});
	};

	async function handleTestToken() {
		// Make your API call to request a new token
		await getUsers(apiToken);
	}

	return (
		<Stack spacing={4}>
			<Card>
				<CardContent>
					<Grid container spacing={3}>
						<Grid xs={12} md={4}>
							<Typography variant='h6'>Changer de mot de passe</Typography>
						</Grid>
						<Grid xs={12} sm={12} md={8}>
							<Stack alignItems='center' direction='row' spacing={3}>
								<TextField
									disabled={!isEditing}
									label='Mot de passe'
									type='password'
									defaultValue='Thebestpasswordever123#'
									sx={{
										flexGrow: 1,
										...(!isEditing && {
											'& .MuiOutlinedInput-notchedOutline': {
												borderStyle: 'dotted'
											}
										})
									}}
								/>
								<CTA
									variant='tertiary'
									onClick={handleEdit}
									title={isEditing ? 'Sauvegarder' : 'Modifier'}
								/>
							</Stack>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
			{isAdmin && (
				<Card>
					<CardContent>
						<Grid container spacing={3}>
							<Grid xs={12} md={4}>
								<Typography variant='h6'>Token API</Typography>
							</Grid>
							<Grid xs={12} sm={12} md={8}>
								<Stack alignItems='center' direction='row' spacing={3}>
									<TextField
										disabled={!isEditing}
										label='Token'
										type={showToken ? 'text' : 'password'}
										defaultValue='BienEssayezMaisLeMotDePasseNestPasCeluiLa42;)'
										sx={{
											width: 'auto',
											flexGrow: 1,
											...(!isEditing && {
												'& .MuiOutlinedInput-notchedOutline': {
													borderStyle: 'dotted'
												}
											})
										}}
										value={apiToken}
										InputProps={{
											readOnly: true,
											endAdornment: apiToken && (
												<InputAdornment position='end'>
													<IconButton onClick={handleToggleSetShowToken}>
														{showToken ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
									{apiToken && (
										<Button onClick={handleCopyClick} startIcon={<FileCopy />}>
											<span>{isCopied ? 'Copied!' : 'Copy'}</span>
										</Button>
									)}
									{apiToken && (
										<Button onClick={handleTestToken} startIcon={<FileCopy />}>
											<span>Test Token</span>
										</Button>
									)}
									<Button onClick={handleRequestToken}>
										{apiToken ? 'Demander un nouveau Token' : 'Demander un Token'}
									</Button>
								</Stack>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			)}

			<Card sx={{ color: bugaColor }}>
				<CardContent>
					<Grid container spacing={3}>
						<Grid xs={12} md={4}>
							<Typography variant='h6'>Supprimer Mon Compte</Typography>
						</Grid>
						<Grid xs={12} md={8}>
							<Stack alignItems='flex-start' spacing={3}>
								<Typography variant='subtitle1'>
									Supprimer votre compte et toutes les données associées. Cela est
									irréversible !
								</Typography>
								<Button
									sx={{
										typography: 'h4',
										fontSize: '0.8rem',
										borderRadius: 5,
										backgroundColor: 'red',
										color: 'white',
										height: '2.5rem',
										mt: 2,
										px: 2,
										width: '50%'
									}}
								>
									Supprimer Mon Compte
								</Button>
							</Stack>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Stack>
	);
};

AccountSecuritySettings.propTypes = {
	loginEvents: PropTypes.array.isRequired
};
