import type { FC } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Card,
	CardContent,
	Stack,
	TextField,
	Typography,
	Unstable_Grid2 as Grid
} from '@mui/material';
import { bugaColor } from '../../../theme';

import { useContext } from 'react';
import { UserContext } from '../../../app/UserContextProvider';

// Define the interface for the component props
interface AccountGeneralSettingsProps {
	avatar: string;
	email: string;
	name: string;
	isAdmin?: boolean;
}

// TODO: add Test with mocks
export const AccountGeneralSettings: FC<
	AccountGeneralSettingsProps
> = props => {
	const context = useContext(UserContext);
	const user = context?.user;
	// Define state variables
	const [isEditMode, toggleEdit] = useState(false);
	const [userFirstName, modifyFirstName] = useState(user?.firstName);
	const [userLastName, modifyLastName] = useState(user?.lastName);
	const [userEmail] = useState(user?.email);
	const [userJob, modifyJob] = useState(user?.job);
	const [userCompany, modifyCompany] = useState(user?.company);

	const save = async () => {
		await context?.updateUser({
			firstName: userFirstName,
			lastName: userLastName,
			job: userJob,
			company: userCompany
		});
		toggleEdit(false);
	};

	if (!user) {
		return null;
	}

	return (
		<Stack spacing={4}>
			<Card sx={{ color: bugaColor }}>
				<CardContent>
					<Grid container spacing={3}>
						<Grid xs={12} md={4}>
							<Typography variant='h6'>Mes informations</Typography>
						</Grid>
						<Grid xs={12} md={8}>
							<Stack spacing={3}>
								<Stack direction='column' spacing={2}>
									<Stack alignItems='center' direction='row' spacing={1}>
										<TextField
											defaultValue={user?.lastName}
											label='Nom'
											disabled={!isEditMode}
											onChange={(e: any) => modifyLastName(e.target.value)}
										/>
										<TextField
											defaultValue={user?.firstName}
											label='Prénom'
											disabled={!isEditMode}
											sx={{ flexGrow: 1 }}
											onChange={(e: any) => modifyFirstName(e.target.value)}
										/>
									</Stack>
									<Stack alignItems='center' direction='row' spacing={1}>
										<TextField
											defaultValue={user?.job}
											label='Poste'
											disabled={!isEditMode}
											sx={{ flexGrow: 1 }}
											onChange={(e: any) => modifyJob(e.target.value)}
										/>
									</Stack>
									<Stack alignItems='center' direction='row' spacing={1}>
										<TextField
											defaultValue={user?.company}
											label='Entreprise'
											disabled={!isEditMode}
											sx={{ flexGrow: 1 }}
											onChange={(e: any) => modifyCompany(e.target.value)}
										/>
									</Stack>
								</Stack>
								<Stack alignItems='center' direction='row' spacing={2}>
									<TextField
										defaultValue={userEmail}
										disabled
										label='Adresse email'
										required
										sx={{
											flexGrow: 1,
											'& .MuiOutlinedInput-notchedOutline': {
												borderStyle: 'dashed'
											}
										}}
									/>
								</Stack>
								<Stack alignItems='center' direction='row' spacing={2}>
									<Button
										color='inherit'
										size='small'
										onClick={() => {
											if (isEditMode) {
												save();
											} else {
												toggleEdit(true);
											}
										}}
									>
										{isEditMode ? 'Sauvegarder' : 'Modifier'}
									</Button>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Stack>
	);
};

AccountGeneralSettings.propTypes = {
	avatar: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	isAdmin: PropTypes.bool
};
