import { createTheme, ThemeOptions } from '@mui/material/styles';
// Blue color
// export const primaryColor = '#ff760f';
export const primaryColor = '#000c7a';
// "Black" classic text
export const primaryColorText = '#17295C';
// Pink color
export const secondaryColor = '#00e7cb';

export const secondaryColorDark = '#fb055a';
export const secondaryColorLight = '#fb055a';
// Blue textp
export const secondaryColorText = '#929EAB';
export const errorColor = '#FF0000';
export const successColor = '#4caf50';
// Grey text
export const hintColorText = '#929EAB';
export const bugaBlackBlue = '#000c7a';
export const bugaColor = '#000f6f';
declare module '@mui/material/styles' {
	interface SimplePaletteColorOptions {
		darkblue?: string;
	}
}
// To be able to use breakpoints helper
const theme = createTheme();

export const themeObject: ThemeOptions = {
	palette: {
		common: {
			black: '#000000',
			white: '#ffffff'
		},
		text: {
			primary: primaryColorText,
			secondary: secondaryColorText,
			disabled: hintColorText
		},
		primary: {
			dark: '#1b1d31',
			main: primaryColor,
			contrastText: '#ffffff',
			darkblue: bugaBlackBlue,
			200: '#90caf9'
		},
		secondary: {
			light: secondaryColorLight,
			main: secondaryColor,
			dark: secondaryColorDark,
			contrastText: '#ffffff'
		},
		error: { main: errorColor },
		success: { main: successColor, light: '#b9f6ca', dark: '#00c853' },
		background: {
			paper: '#FAFAFF',
			default: '#12121e'
		},
		grey: { 500: hintColorText }
	},
	typography: {
		fontFamily: '"Montserrat", sans-serif',
		h1: {
			fontSize: '1.8rem',
			fontWeight: 'bold',
			lineHeight: '1.5'
		},
		h2: {
			fontSize: '1.2rem',
			lineHeight: '1.5',
			fontWeight: '700'
		},
		h3: {
			fontSize: '0.8rem',
			fontWeight: '500',
			sm: {
				fontSize: '1rem'
			}
		},
		h4: {
			fontSize: '3rem',
			fontWeight: 'bolder',
			color: '#000c7a'
		},
		h5: {
			fontWeight: 'normal',
			fontSize: '1rem'
		},
		h6: {
			fontSize: '1.2rem'
		},
		subtitle1: {
			fontWeight: '500',
			fontSize: '0.7rem',
			letterSpacing: '0.5',
			textTransform: 'uppercase'
		},
		subtitle2: {
			fontWeight: 'normal',
			fontSize: '0.875rem'
		},
		body1: {
			fontSize: '0.8rem',
			lineHeight: 1.5,
			fontWeight: 'normal'
		},
		body2: {
			fontWeight: 'normal',
			fontSize: '0.7rem',
			lineHeight: 1.5
		},
		caption: {
			fontWeight: 'normal',
			letterSpacing: '0.05rem',
			lineHeight: 1,
			textTransform: 'uppercase',
			fontSize: '0.6rem'
		},
		button: {
			fontSize: '1rem',
			textTransform: 'none'
		}
	},
	components: {
		MuiTabs: {
			styleOverrides: {
				indicator: {
					backgroundColor: secondaryColorText
				}
			}
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					background: 'transparent',
					boxShadow: 'none'
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 12,
					boxShadow: `1px 5px 7px 2px rgba(0,0,0,0.1)`,
					fontSize: '0.8rem',
					textTransform: 'none',
					lineHeight: 1.5,
					padding: '10px 15px',
					border: 'solid 2px',
					fontWeight: 600,
					boxSizing: 'border-box',
					'&:hover': {
						background: primaryColor,
						color: 'white',
						border: `solid 2px ${primaryColor}`
					}
				},
				contained: {
					color: 'white',
					border: `solid 2px ${primaryColor}`,
					'&$disabled': {
						color: hintColorText,
						backgroundColor: '#f2f2f2'
					},
					'&:hover': {
						border: `solid 2px ${primaryColor}`
					}
				},
				outlined: {
					fontWeight: 600,
					background: 'transparent',
					border: `solid 2px ${primaryColor}`,
					color: primaryColor,
					'&$disabled': {
						color: hintColorText,
						backgroundColor: '#f2f2f2',
						border: `solid 2px ${primaryColor}`
					}
				},
				text: {
					border: `none`
				},
				sizeSmall: {
					fontSize: '10px',
					padding: '5px 8px',
					sm: {
						padding: '10px 10px'
					}
				}
			}
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					background: '#F8F8F8'
				}
			}
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: '#bdbdbd'
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					fontSize: '1.2rem'
				}
			}
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					display: 'block'
				}
			}
		},
		MuiFab: {
			styleOverrides: {
				root: {
					background: 'white',
					color: primaryColor,
					border: 'solid 2px',
					'&:hover': {
						background: primaryColor,
						color: 'white'
					}
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {}
			}
		},
		MuiInput: {
			styleOverrides: {
				root: {
					background: 'blue',
					'&$outlined': {
						background: 'blue'
					}
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: 'uppercase',
					letterSpacing: '0.2rem',
					width: 'auto !important',
					padding: '0 2rem'
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				icon: {
					color: '#3bb4fa'
				}
			}
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					color: '#ffffff'
				},
				colorPrimary: {
					color: '#000000'
				}
			}
		},
		MuiSvgIcon: {
			styleOverrides: {
				fontSizeLarge: {
					fontSize: '6rem'
				}
			}
		}
	},
	spacing: 8
};

const compiledTheme = createTheme(themeObject);

export default compiledTheme;
