/* eslint-disable no-useless-escape */
// Ré-écrit en TS depuis https://github.com/bibliolabs/docCookies
export const getItem = (sKey: string) =>
	decodeURIComponent(
		window.document.cookie.replace(
			new RegExp(
				`(?:(?:^|.*;)\\s*${encodeURIComponent(sKey).replace(
					/[\-\.\+\*]/g,
					'\\$&'
				)}\\s*\\=\\s*([^;]*).*$)|^.*$`
			),
			'$1'
		)
	) || undefined;

export const setItem = (
	sKey: string,
	sValue: string,
	vEnd?: any,
	sPath?: string,
	sDomain?: string,
	bSecure?: boolean
) => {
	if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
		return false;
	}
	let sExpires = '';
	if (vEnd) {
		switch (vEnd.constructor) {
			case Number:
				sExpires =
					vEnd === Infinity
						? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
						: `; max-age=${vEnd}`;
				break;
			case String:
				sExpires = `; expires=${vEnd}`;
				break;
			case Date:
				sExpires = `; expires=${vEnd.toUTCString()}`;
				break;
			default:
		}
	}
	window.document.cookie = `${encodeURIComponent(sKey)}=${encodeURIComponent(
		sValue
	)}${sExpires}${sDomain ? `; domain=${sDomain}` : ''}${
		sPath ? `; path=${sPath}` : ''
	}${bSecure ? '; secure' : ''}`;
	return true;
};

export const removeItem = (sKey: string, sPath?: string, sDomain?: string) => {
	if (!hasItem(sKey)) {
		return false;
	}
	window.document.cookie = `${encodeURIComponent(
		sKey
	)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${
		sDomain ? `; domain=${sDomain}` : ''
	}${sPath ? `; path=${sPath}` : ''}`;
	return true;
};

export const hasItem = (sKey: string) => {
	if (!sKey) {
		return false;
	}
	return new RegExp(
		`(?:^|;\\s*)${encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&')}\\s*\\=`
	).test(window.document.cookie);
};

export const keys = () => {
	const aKeys = window.document.cookie
		.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
		.split(/\s*(?:\=[^;]*)?;\s*/);
	for (let nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx += 1) {
		aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
	}
	return aKeys;
};
