import {
	Container,
	Divider,
	Stack,
	Tab,
	Tabs,
	Typography
} from '@mui/material';
import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import { Page } from '../Page/Page';
import { AccountSecuritySettings } from './SettingsTabs/AccountSecuritySettings';
import { AccountGeneralSettings } from './SettingsTabs/AccountGeneralSettings';

import { useContext } from 'react';
import { UserContext } from '../../app/UserContextProvider';

const tabs = [
	{ label: 'Général', value: 'general' },
	{ label: 'Sécurité', value: 'security' }
];

export const SettingsPage = () => {
	const context = useContext(UserContext);
	const user = context?.user;
	const isAdmin = context?.isAdmin;

	const [currentTab, setCurrentTab] = useState<string>('general');
	const handleTabsChange = useCallback(
		(event: ChangeEvent<any>, value: string): void => {
			setCurrentTab(value);
		},
		[]
	);
	return (
		<Page>
			<div className='mx-8'>
				<h2>Mon profil</h2>
				<Tabs
					indicatorColor='primary'
					onChange={handleTabsChange}
					scrollButtons='auto'
					textColor='primary'
					value={currentTab}
					variant='scrollable'
				>
					{tabs.map(tab => (
						<Tab key={tab.value} label={tab.label} value={tab.value} />
					))}
				</Tabs>
				<Divider />
				{currentTab === 'general' && (
					<AccountGeneralSettings
						avatar={''}
						email={user?.email || ''}
						name={user?.firstName || ''}
						isAdmin={isAdmin}
					/>
				)}
				{currentTab === 'security' && <AccountSecuritySettings loginEvents={[]} />}
			</div>
		</Page>
	);
};
